<template>
    <div class="zl-list">
        <div class="title flts-title">
            <h3>财务期间设置</h3>
            <!-- <a-button type="primary" @click="showEditModal()" >快捷设置</a-button> -->
        </div>
        <div class="clearfix table-tools">
            <div class="search" style="padding-bottom:10px;">
                <a-form layout='inline'>
                    <a-form-item>
                        <a-select style="width:100px" :value="year" @change="handleChangeTime">
                            <a-select-option v-for="(d, index) of yearList" :key="index" :value="d">{{ d }}</a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item>
                    <a-button type="primary" @click="searchList">搜索</a-button>
                    </a-form-item>
                </a-form>
            </div>
        </div>
        <div class="table">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-table size="small" :pagination="false" :bordered='false' rowKey="month"
                :columns="columns" :dataSource="list">
                <template slot="start" slot-scope="text, record , index">
                    <span v-if="!record.is_edit">{{text}}</span>
                    <a-date-picker :defaultValue="record.startF" v-if="record.is_edit" @change="e=>onChange(e,'startF',record)" />
                </template>
                <template slot="end" slot-scope="text, record , index">
                    <span v-if="!record.is_edit">{{text}}</span>
                    <a-date-picker :defaultValue="record.endF" v-if="record.is_edit" @change="e=>onChange(e,'endF',record)" />
                </template>
                <template slot="action" slot-scope="text,record">
                    <div>
                        <a href="javascript:;" key="1">
                            <a-icon @click="showTime(record)" v-if="!record.is_edit && !record.is_lock" type="edit" />
                            <a-space v-if="record.is_edit">
                                <a-button @click="confirmDate(record)" type="primary">确认</a-button>
                                <a-button @click="record.is_edit = false">取消</a-button>
                            </a-space>
                        </a>
                        <a-divider v-if="!record.is_lock" type="vertical" />
                        <a href="javascript:;" key="2">
                            <a-tooltip v-if="record.is_lock">
                                <template slot="title">
                                财务期间已锁定，该财务期间范围内，其收费记录、上课记录都无法再进行任何操作（如上课点名、作废合同、退费等）。
                                </template>
                                <a-icon @click="toLock(2,record)" type="lock" />
                            </a-tooltip>

                            <a-tooltip v-else>
                                <template slot="title">
                                为防止以往的财务记录被随意更改，您可以对财务期间进行锁定。锁定后的财务期间范围内，其收费记录、上课记录都无法再进行任何操作（如上课点名、作废合同、退费等）。
                                </template>
                                <a-icon @click="toLock(1,record)" type="unlock" />
                            </a-tooltip>
                        </a>
                    </div>
                </template>
            </a-table>
        </div>
        <editModal :item='modalData' v-if="isEditModal" />
    </div>
</template>

<script>
const columns = [
  { title: '年份', dataIndex: 'year', key: 'year' },
  { title: '月份', dataIndex: 'month', key: 'month' },
  { title: '开始日期', dataIndex: 'start', key: 'start' , scopedSlots: { customRender: 'start' }},
  { title: '结束日期', dataIndex: 'end', key: 'end' , scopedSlots: { customRender: 'end' }},
  { title: '操作', key: 'operation', fixed: 'right', scopedSlots: { customRender: 'action' }}
]

    import editModal from './editModal'
    import moment from "moment"
    export default {
        name:'financeSection',
        data() {
            return {
                columns,
                list:[],
                loading:false,
                isEditModal:false,
                modalData:{},
                year: '',
                yearList:[],
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        components: {
            editModal,
        },
        created () {
            let defaultYear = 2015
            let nowYear = Number(moment().add(2, 'year').format('YYYY'))
            let yearList = [nowYear]
            for(let i=0; i <= nowYear-defaultYear; i++){
                nowYear-=1
                yearList.push(nowYear)
            }
            this.yearList = yearList
            this.year = Number(moment().format('YYYY'))
            this.getList()
        },
        methods: {
            moment,
            async getList(){
                this.loading = true
                await this.$store.dispatch('settingfinanceFiscalAction',{data:{year:this.year}})
                .then(res=>{
                    this.list = res.data
                    this.loading = false
                })
            },
            showEditModal(item){
                this.modalData = item
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val){
                    this.getList()
                }
                this.isEditModal = false
            },
            handleChangeTime(e){
                this.year = e
            },
            searchList(e) {
                this.getList()
            },
            onChange(e,name,item){
                item[name] = moment(e)
            },
            showTime(item){
                item.startF = moment(item.start)
                item.endF = moment(item.end)
                item.is_edit = true
            },
            async confirmDate(item){
                let obj = {
                    year:item.year,
                    month:item.month,
                    start:item.startF?moment(item.startF).format("YYYY-MM-DD"):item.start,
                    end:item.endF?moment(item.endF).format("YYYY-MM-DD"):item.end,
                }
                await this.$store.dispatch("settingfinanceSetFiscalAction",{data:obj})
                .then(res=>{
                    this.$message.success('操作成功！！')
                    this.getList()
                })
                .catch(err=>{})
            },
            toLock(num,item){
                let that = this
                let title = num == 1?'确定要锁定？锁定后该时间范围内不能进行收费、签到、撤销上课、作废合同、退费等操作':'确定要解锁吗？财务期间解锁后，其对应范围内的收费、课消信息有可能会被再次修改，从而导致以往的财务数据发生变化。'
                this.$confirm({
                    title,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        await that.$store.dispatch(num ==1?'settingfinanceLockFiscalAction':'settingfinanceUnlockFiscalAction', { data: { year: item.year,month:item.month } })
                        .then(res=>{
                            that.$message.success('操作成功！！')
                            that.getList()
                            resolve(res)
                        })
                        .catch(err=>{
                            that.$message.error('操作失败！！')
                            resolve(err)
                        })
                    }).catch(error => console.log(error))
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>