<template>
    <div>
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-form :form="form">
            <a-form-item label="app_secret" >
                <a-input v-decorator="rules.app_secret" style="width:450px"/>
            </a-form-item>
            <a-form-item label="直播应用Secret" >
                <a-input v-decorator="rules.live_secret" style="width:450px"/>
            </a-form-item>
            <a-form-item label="公费电话Secret" >
                <a-input v-decorator="rules.telephone_secret" style="width:450px"/>
            </a-form-item>
            <a-form-item label="会话内容存档Secret" >
                <a-input v-decorator="rules.financial_secret" style="width:450px"/>
            </a-form-item>
            <a-form-item label="会话内容存档PRIVATE KEY" >
                <a-textarea v-decorator="rules.financial_private_key" style="width:90%" :auto-size="{ minRows: 12, maxRows: 12 }"/>
            </a-form-item>
        </a-form>
    </div>
</template>

<script>
    export default {
        name:'conversation',
        inject: ['parent'],
        data() {
            return {
                loading: false,
                confirmLoading: false,
                form: this.$form.createForm(this),
                rules: {
                    app_secret: ['app_secret', { rules: [{ required: true, message: '请输入APP_SECRET' }] }],
                    live_secret: ['live_secret', { rules: [] }],
                    telephone_secret: ['telephone_secret', { rules: [] }],
                    financial_secret: ['financial_secret', { rules: [{ required: false, message: '请输入会话内容存档Secret' }] }],
                    financial_private_key: ['financial_private_key', { rules: [{ required: false, message: '请输入会话内容存档PRIVATE KEY' }] }],
                },
            }
        },
        created () {
            this.getList()
        },
        methods: {
            async getList() {
                this.loading = true
                let res = await this.$store.dispatch('settingWxworkAction', { })
                let obj = {
                    // corp_id:res.data.corp_id,
                    // agent_id:res.data.agent_id,
                    app_secret:res.data.app_secret,
                    live_secret:res.data.live_secret,
                    telephone_secret:res.data.telephone_secret,
                    financial_secret:res.data.financial_secret,
                    financial_private_key:res.data.financial_private_key,
                }
                this.form.setFieldsValue(obj)
                this.loading = false
            },
            async handleUpdate(){
               let params =  this.form.getFieldsValue()
               this.loading = true
               this.parent.loading = true
               await this.$store.dispatch('settingWxworkUpdateAction', {data:params})
               .then(res=>{
                   this.$message.success('操作成功~')
                   this.loading = false
                   this.parent.loading = false
               })
               .catch(err=>{
                   this.$message.error('操作失败~')
                   this.loading = false
                   this.parent.loading = false
               })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>