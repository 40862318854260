<template>
  <div class="zcxy zl-list">
    <div class="title flts-title">
        <h3>模版消息设置</h3>
        <a-button @click="refresh" type="primary">刷新</a-button>
    </div>
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="small" :pagination="false" :bordered='false' rowKey="template_id"
        :columns="columns" :dataSource="list">

          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>

          <template slot="name" slot-scope="text, record">
            <div>{{text}}</div>
            <div>{{record.template_id}}</div>
          </template>

          <template slot="color" slot-scope="text, record">
            <swatches
              v-model="record.color"
              show-fallback
              popover-to="right"
              shapes="circles"
              row-length="6"
              @open='openColor(record)'
              @close="saveColor(record)"
            ></swatches>
            <!-- <span>{{ text }}</span> -->
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '消息颜色', width: '90px', dataIndex: 'color', key: '1' , scopedSlots: { customRender: 'color' } },
  { title: '模板标题/模板ID', width: '380px', dataIndex: 'title', key: 'name', scopedSlots: { customRender: 'name' } },
  { title: '模板消息内容', dataIndex: 'content', key: '3' }
]

import C_ITEMS from '@/utils/items'
import Swatches from 'vue-swatches'
import "vue-swatches/dist/vue-swatches.min.css"
import tableMixins from '@/common/mixins/table'
export default {
  name: 'templateMessage',
  components: {
    Swatches,
  },
  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      dealStatus: C_ITEMS.dealStatus,
      studios: [],
      consultants: [],
      channels: [],

      searchData: {},

      visible: false,
      beforeColor:'',
    }
  },
  mixins:[tableMixins],

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },

    async getConsultant() {
      let res = await this.$store.dispatch('searchConsultantAction', {})
      this.consultants = res.data
    },

    async getSourceChannel(parent_id) {
      let searchParams = {}
      if (parent_id) {
        searchParams.parent_id = parent_id
      }
      let res = await this.$store.dispatch('searchSourceChannelAction', { data: searchParams })
      if (parent_id) {
        this.channels = res.data
      } else {
        this.parentChannels = res.data
      }
    },
    async getList() {
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'created_date') {
            obj['start_date'] = searchData[k][0]
            obj['end_date'] = searchData[k][1]
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('weixinTemplateAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    openColor(item){
      this.beforeColor = item.color
    },
    async saveColor(item) {
      if(this.beforeColor == item.color){
        return false
      }
      await this.$store.dispatch('weixinTemplateColorAction', { data: item })
      this.getList()
    },
    async refresh(){
      this.loading = true
      await this.$store.dispatch('weixinTemplateRefreshAction', {})
      .then(res=>{
        this.getList()
      })
      .catch(err=>{
        this.loading = false
      })
    }
  }
}
</script>
