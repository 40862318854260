<template>
  <div class="zl-list">
    <div class="title flts-title">
        <h3>招生规则设置</h3>
    </div>
    <div>
      <a-tabs :activeKey='activeKey' @change="handleChange">
        <a-tab-pane key="1" tab="招生规则设置">
          <div v-if="activeKey === '1'">
            <contractExpire />
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="拥有客户数限制" force-render>
          <div v-if="activeKey === '2'">
            <customerCount />
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
    import customerCount from './customerCount'
    import contractExpire from './contractExpire'
    export default {
        name:'contractExpireSet',
        data() {
            return {
                activeKey: '1'
            }
        },
        components: {
            customerCount,
            contractExpire,
        },
        methods: {
            handleChange(e){
                this.activeKey = e
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>