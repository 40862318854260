<template>
  <div class="zl-list">
    <div class="title flts-title">
        <h3>短信服务</h3>
    </div>
    <a-tabs v-model="active" @change="callback">
      <a-tab-pane :key="1" tab="短信充值">
          <recharge v-if="active === 1"/>
      </a-tab-pane>
      <a-tab-pane :key="2" tab="短信统计">
          <analysis v-if="active === 2"/>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
    import recharge from './recharge'
    import analysis from './analysis'

    export default {
        name:'SMSService',
        data() {
            return {
                active:1
            }
        },
        components: {
            recharge,
            analysis
        },
        methods: {
            callback(e){

            }
        },
    }
</script>

<style lang="scss">

</style>