<template>
    <div class="zl-list FollowLogTypeSet">
        <div class="title flts-title">
            <h3>跟进记录类型设置</h3>
            <a-button @click="handleSubmit" type="primary">保存</a-button>
        </div>
        <a-form :form="form" @submit="handleSubmit">
            <a-form-item
            
            v-for="(k, index) in form.getFieldValue('keys')"
            :key="k"
            v-bind="formItemLayoutWithOutLabel"
            :required="false"
            style="margin-bottom:8px"
            >
            <a-input
            @mouseover="showIcon(index)"
            @mousemove="hiddenIcon"
                v-decorator="[
                `names[${k}]`,
                {
                    validateTrigger: ['change', 'blur'],
                },
                ]"
                placeholder="请输入..."
                style="width: 40%; margin-right: 8px"
            />
            <a-icon
                v-if="iconIndex == index"
                class="dynamic-delete-button"
                type="minus-circle-o"
                @click="() => remove(k)"
            />
            </a-form-item>
            <a-form-item v-bind="formItemLayoutWithOutLabel">
            <a-button type="dashed" @click="add">
                <a-icon type="plus" /> 添加类型
            </a-button>
            </a-form-item>
        </a-form>
    </div>
</template>
<script>
let id = 0;
export default {
    name:'FollowLogTypeSet',
  data() {
    return {
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 10, offset: 0 },
          sm: { span: 20, offset: 0 },
        },
      },
      iconIndex:-1,
      loading:false
    };
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'dynamic_form_item' });
    this.form.getFieldDecorator('keys', { initialValue: [], preserve: true });
  },
  methods: {
    remove(k) {
      const { form } = this;
      const keys = form.getFieldValue('keys');
      form.setFieldsValue({
        keys: keys.filter(key => key !== k),
      });
    },

    add() {
      const { form } = this;
      const keys = form.getFieldValue('keys');
      const nextKeys = keys.concat(id++);
      form.setFieldsValue({
        keys: nextKeys,
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      console.log(e)
      this.form.validateFields((err, values) => {
        if (!err) {
          const { keys, names } = values;
          console.log('Received values of form: ', values);
          console.log(
            'Merged values:',
            keys.map(key => names[key]),
          );
        }
      });
    },
    showIcon(index){
        this.iconIndex = index;
    },
    hiddenIcon(){
    //    this.iconIndex = -1; 
    }
  },
};
</script>