<template>
  <div class="zl-list">
    <div class="title flts-title">
        <h3>企业微信授权</h3>
        <a-button v-if="activeKey == 2" :loading="loading" type="primary" @click="toSave">保存</a-button>
    </div>
    <div>
        <a-tabs :activeKey="activeKey" @change="changeTab">
            <a-tab-pane key="1">
            <span slot="tab">应用授权</span>
            <wechat v-if="activeKey === '1'" />
            </a-tab-pane>
            <a-tab-pane key="2">
            <span slot="tab">应用参数</span>
            <conversation ref="conversation" v-if="activeKey === '2'" />
            </a-tab-pane>
        </a-tabs>
    </div>
  </div>
</template>

<script>
    import wechat from './wechat'
    import conversation from './conversation'
    export default {
        name:"enterpriseWechat",
        provide() {
            return {
                parent: this
            }
        },
        data() {
            return {
                activeKey: '1',
                loading:false
            }
        },
        components: {
            wechat,
            conversation
        },
        methods:{
            changeTab(key) {
                this.activeKey = key
            },
            toSave(){
                this.$refs.conversation.handleUpdate()
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>