<template>
    <div class="zl-list">
        <div class="title flts-title">
            <h3>约课设置</h3>
            <a-button type="primary" @click="showEditModal()" >快捷设置</a-button>
        </div>
        <div class="clearfix table-tools"></div>
        <div class="table">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
                :columns="columns" :dataSource="list">

                <template slot="index" slot-scope="text, record , index">
                    <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                </template>
                <template slot="studio_names" slot-scope="text, record">
                    <span v-if="record.studio_names.length === 0">--</span>
                    <a-dropdown v-else>
                        <a style="cursor:pointer" class="ant-dropdown-link" @click="e => e.preventDefault()">
                            {{record.studio_names[0]}}等{{record.studio_names.length}}个校区<a-icon type="caret-down" />
                        </a>
                        <a-menu style="max-height:400px;overflow-y: auto;" slot="overlay">
                            <a-menu-item style="text-align: center" v-for="(it,j) in record.studio_names" :key="j">
                                {{it}}
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </template>

                <template slot="class_names" slot-scope="text, record">
                    <span v-if="record.class_names.length === 0">--</span>
                    <a-dropdown v-else>
                        <a style="cursor:pointer" class="ant-dropdown-link" @click="e => e.preventDefault()">
                            {{record.class_names[0]}}等{{record.class_names.length}}个班级<a-icon type="caret-down" />
                        </a>
                        <a-menu style="max-height:400px;overflow-y: auto;" slot="overlay">
                            <a-menu-item style="text-align: center" v-for="(it,j) in record.class_names" :key="j">
                                {{it}}
                            </a-menu-item>
                        </a-menu>
                    </a-dropdown>
                </template>

                <template slot="action" slot-scope="text,record">
                    <a href="javascript:;" @click="toDel(record)" >删除</a>
                </template>

            </a-table>
        </div>
        <div class="page">
        <a-pagination
            :pageSizeOptions="pageSizeOptions"
            :total="pageParams.totalCount"
            showSizeChanger
            :pageSize="pageParams.perPage"
            v-model="current"
            @change="changePage"
            @showSizeChange="onShowSizeChange"
            :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
                <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                <span v-if="props.value==='100'">100条/页</span>
            </template>
            </a-pagination>
        </div>
        <editModal :item='modalData' v-if="isEditModal" />
    </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '开始日期', dataIndex: 'open_date', key: 'open_date'},
  { title: '结束日期', dataIndex: 'close_date', key: 'close_date'},
  { title: '适用校区', dataIndex: 'studio_names', key: 'studio_names', scopedSlots: { customRender: 'studio_names' },align:'center'},
  { title: '适用班级', dataIndex: 'class_names', key: 'class_names', scopedSlots: { customRender: 'class_names' },align:'center'},
  { title: '折扣名称', dataIndex: 'discount_name', key: 'discount_name'},
  { title: '定时开放', dataIndex: 'rule_enable_date_time', key: 'rule_enable_date_time'},
  { title: '操作人', dataIndex: 'created_by', key: 'created_by'},
  { title: '操作时间', dataIndex: 'created_at', key: 'created_at'},
  { title: '操作', key: 'operation', fixed: 'right', scopedSlots: { customRender: 'action' }}
]

    import editModal from './editModal'
    import moment from "moment"
    import tableMixins from '@/common/mixins/table'
    export default {
        name:'appointment',
        data() {
            return {
                columns,
                list:[],
                loading:false,
                isEditModal:false,
                modalData:{},
                year: '',
                yearList:[],
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        components: {
            editModal,
        },
        mixins:[tableMixins],
        methods: {
            moment,
            async getList(){
                this.loading = true
                await this.$store.dispatch('settingSystemEducationAppointmentAction',{})
                .then(res=>{
                    this.list = res.items
                    this.pageParams = res._meta
                    this.loading = false
                })
            },
            showEditModal(item){
                this.modalData = item
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val){
                    this.getList()
                }
                this.isEditModal = false
            },
            searchList(e) {
                this.getList()
            },
            toDel(item){
                let that = this
                this.$confirm({
                    title: `您确定该约课设置`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    return new Promise(async (resolve, reject) => {
                        await that.$store.dispatch('settingSystemEducationDeleteAppointmentAction', { rule_id: item.id })
                        .then(res=>{
                            that.getList()
                            resolve(res)
                        })
                    }).catch(error => console.log(error))
                    }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>