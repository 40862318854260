<template>
  <div class="zl-list">
    <div class="title flts-title">
        <h3>支付设置</h3>
        <a-button type="primary" @click="submitData"  :loading='confirmLoading'>保存</a-button>
    </div>
    <div>
      <a-tabs :activeKey='activeKey' @change="handleChange">
        <a-tab-pane key="1" tab="微信支付">
          <div v-if="activeKey === '1'">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-form :form="form">
              <a-form-item label="商户号" >
                <a-input v-decorator="rules.mchid" style="width:350px"/>
              </a-form-item>
              <a-form-item label="商户支付密钥" >
                <a-input v-decorator="rules.key" style="width:350px"/>
              </a-form-item>
              <a-form-item label="退款授权KEY" >
                <div>
                      <a-upload
                          name="file"
                          :showUploadList='false'
                          accept=".pem"
                          :beforeUpload="beforeUpload"
                      >   
                          <div style="display:flex">
                              <a-input style="margin-right:10px;width:350px" v-decorator="rules.apiclient_key_name"  disabled />
                              <a-button><a-icon type="upload" /> 选择文件 </a-button>
                          </div>
                      </a-upload>
                </div>
              </a-form-item>
              <a-form-item label="退款授权CERT" >
                <div>
                      <a-upload
                          name="file"
                          accept=".pem"
                          :showUploadList='false'
                          :beforeUpload="beforeUploadTwo"
                      >   
                          <div style="display:flex">
                              <a-input style="margin-right:10px;width:350px" v-decorator="rules.apiclient_cert_name" disabled />
                              <a-button><a-icon type="upload" /> 选择文件 </a-button>
                          </div>
                      </a-upload>
                </div>
              </a-form-item>
            </a-form>
          </div>
        </a-tab-pane>
        <a-tab-pane key="2" tab="支付宝支付" force-render>
          <div v-if="activeKey === '2'">
            <vue-element-loading :active="loadingF" color="#00cca2" spinner="spinner"/>
            <a-form :form="form">
              <a-form-item label="是否启用" >
                <a-switch :checked='alipay_status' @change="handleSwitch"/>
              </a-form-item>
              <a-form-item v-show="alipay_status" label="应用ID" >
                <a-input v-decorator="rules.app_id" style="width:350px"/>
              </a-form-item>
              <a-form-item v-show="alipay_status" label="支付宝公钥" >
                <a-textarea auto-size v-decorator="rules.alipay_public_key" style="width:90%" :auto-size="{ minRows: 3, maxRows: 5 }"/>
              </a-form-item>
              <a-form-item v-show="alipay_status" label="商户私钥" >
                <a-textarea auto-size v-decorator="rules.merchant_private_key" style="width:90%" :auto-size="{ minRows: 12, maxRows: 12 }"/>
              </a-form-item>
            </a-form>
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
export default {
  name: 'wexinPay',
  data() {
    return {
      imageUrl: '', 
      loading: false,
      loadingF: false,
      confirmLoading: false,
      fileList:null,
      fileListTwo:null,
      form: this.$form.createForm(this),
      rules: {
        mchid: ['mchid', { rules: [{ required: true, message: '请输入商户号!' }] }],
        key: ['key', { rules: [{ required: true, message: '请输入商户支付密钥!' }] }],
        apiclient_cert_name: ['apiclient_cert_name', { rules: [] }],
        apiclient_key_name: ['apiclient_key_name', { rules: [] }],
        app_id: ['app_id', { rules: [{ required: true, message: '请输入应用ID!' }] }],
        alipay_public_key: ['alipay_public_key', { rules: [{ required: true, message: '请输入支付宝公钥!' }] }],
        merchant_private_key: ['merchant_private_key', { rules: [{ required: true, message: '请输入商户私钥!' }] }],
      },
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: {},
      activeKey:'1',
      alipay_status:true,
    }
  },

  created() {
    this.getList()
  },

  methods: {
    async getList() {
      this.loading = true
      let { searchData } = this
      let res = await this.$store.dispatch('weixinPaymentAction', { data: this.searchParams })
      this.form.setFieldsValue(res.data)
      this.loading = false
    },
    async getAlipay() {
      this.loadingF = true
      let res = await this.$store.dispatch('settingAliPaymentAction', {})
      this.alipay_status = res.data.alipay_status == 1?true:false
      delete res.data.alipay_status
      this.form.setFieldsValue(res.data)
      this.loadingF = false
    },
    async submitData() {
        let data = await this.form.validateFields()
        this.confirmLoading = true
        if(this.activeKey === '1'){
          const { fileList , fileListTwo} = this
          const formData = new FormData();
          formData.append('mchid', data.mchid);
          formData.append('key', data.key);
          if(fileList){
              formData.append('apiclient_key', fileList);
          }
          if(fileListTwo){
              formData.append('apiclient_cert', fileListTwo);
          }
          let res = await this.$store.dispatch('weixinPaymentUpdateAction', formData)
          .then(res=>{
            this.$message.success('保存成功！')
            this.confirmLoading=false
          })
          .catch(err=>{
            this.$message.warning('保存失败！')
            this.confirmLoading=false
          })
        }else{
          data.alipay_status = this.alipay_status?1:0
          let res = await this.$store.dispatch('settingAliPaymentUpdateAction', {data})
          .then(res=>{
            this.$message.success('保存成功！')
            this.confirmLoading=false
          })
          .catch(err=>{
            this.$message.warning('保存失败！')
            this.confirmLoading=false
          })
        }
    },
    beforeUpload(file){
        this.form.setFieldsValue({apiclient_key_name:file.name})
        this.fileList = file;
        return false
    },
    beforeUploadTwo(file){
        this.form.setFieldsValue({apiclient_cert_name:file.name})
        this.fileListTwo = file;
        return false
    },
    handleChange(e){
      this.activeKey = e
      if(e === '1'){
        this.getList()
      }else{
        this.getAlipay()
      }
    },
    handleSwitch(e){
      this.alipay_status = e
    }
  }
}
</script>
