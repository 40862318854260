<template>
    <div>
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <div>
            <a-card style="width: 320px">
                <a-statistic title="短信余额" :value="count" :value-style="{ color: '#00cca2' }" style="margin-right: 50px" />
                <a-alert v-if="count<100" message="短信余额小于100条，请尽快充值" type="warning" show-icon />
            </a-card>
        </div>
        <div>
            <div style="display:flex;align-items:baseline;margin-top:15px">
                <h2 style="margin-right:10px">短信充值资源包</h2>
                <h4>( 用于验证码类、时间通知等触发类短信；资源包无使用期限, 短信成功计费, 失败立即返还 )</h4>
            </div>
            <div class="sms-list">
                <a-card hoverable v-for="item in list" :key="item.product_id" style="width: 250px;margin-right:15px;margin-bottom:15px;">
                    <div class="smsCard">
                        <h2>{{item.product_name}}</h2>
                        <h2>{{item.product_sms_count}}</h2>
                        <div>短信发送许可</div>
                        <div class="smsCard-line"></div>
                        <h2 style="color:#00cca2">¥{{item.sale_price}}</h2>
                        <div style="margin-bottom:10px">单价 {{item.sale_price/item.product_sms_count}}/条</div>
                        <a-button @click.stop="showQrModal(item)" type="primary" icon="wechat">微信支付</a-button>
                    </div>
                </a-card>
            </div>
        </div>
        <qrModal v-if="isQrModal" :item="modalData" />
        <div v-if="isQrModal" @click="hideQrModal" class="smsCard-bg"></div>
    </div>
</template>

<script>
    import qrModal from './qrModal'

    export default {
        name:'recharge',
        data() {
            return {
                loading:false,
                list:[],
                count:0,
                isQrModal:false,
                modalData:{},
            }
        },
        provide() {
            return {
            parent: this
            }
        },
        components:{qrModal},
        created () {
            this.getList()
        },
        methods: {
            async getList(){
                this.loading = true
                let res = await this.$store.dispatch('settingSystemSmsAction')
                this.count = res.data.sms_count
                this.list = res.data.sms_product_list
                this.loading = false
            },
            showQrModal(val){
                this.modalData = val
                this.isQrModal = true
            },
            hideQrModal(val){
                if(val){
                    this.getList()
                }
                this.isQrModal = false
            }
        },
    }
</script>

<style lang="scss">
    .sms-list{
        display: flex;
        flex-wrap:wrap;
    }
    .smsCard-bg{
        position: fixed;
        z-index: 4000;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0,0,0,0.4);
    }
    .smsCard{
        text-align: center;
        &-line{
            border-top:1px solid #e8e8e8;
            margin: 10px 0;
        }
        &-btn{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 120px;
            height: 32px;
            line-height: 32px;
            padding: 0 15px;
            font-size: 14px;
            border-radius: 2px;
            color: #00cca2;
            // background-color: #00cca2;
            border: 1px solid #00cca2;
            box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
            margin: 0 auto;
        }
    }
</style>