<template>
    <div class="zl-list">
        <div class="title flts-title">
            <h3>教务系统</h3>
            <a-button type="primary" @click="submitData" :loading='loading' >保存</a-button>
        </div>
        <div style="height:650px;overflow:auto;">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-form :form="form" layout='vertical'>
                <a-form-item label="是否可以请假:" >
                    <a-radio-group v-decorator="['lesson_can_cancel']">
                        <a-radio :value="1">可以</a-radio>
                        <a-radio :value="0">不可以</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="请假是否扣课时:" >
                    <a-radio-group v-decorator="['lesson_cancel_need_costs']">
                        <a-radio :value="0">不扣课时</a-radio>
                        <a-radio :value="1">扣课时</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="老师提前通知上课时间:" >
                    <a-radio-group v-decorator="['lesson_alarm_teacher']">
                        <a-radio :value="0">不通知</a-radio>
                        <a-radio :value="24">提前二十四小时</a-radio>
                        <a-radio :value="48">提前四十八小时</a-radio>
                        <a-radio :value="72">提前七十二小时</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="学员提前通知上课时间:" >
                    <a-radio-group v-decorator="['lesson_alarm_student']">
                        <a-radio :value="0">不通知</a-radio>
                        <!-- <a-radio :value="12">提前十二小时</a-radio> -->
                        <a-radio :value="24">提前二十四小时</a-radio>
                        <a-radio :value="48">提前四十八小时</a-radio>
                        <a-radio :value="72">提前七十二小时</a-radio>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="学员提前请假时间:" >
                    <a-radio-group v-decorator="['lesson_cancel_before_hours']">
                        <a-radio :value="0">不可以请假</a-radio>
                        <a-radio :value="1">提前一小时</a-radio>
                        <a-radio :value="3">提前三小时</a-radio>
                        <a-radio :value="12">提前十二小时</a-radio>
                        <a-radio :value="24">提前二十四小时</a-radio>
                        <a-radio :value="36">提前三十六小时</a-radio>
                        <a-radio :value="48">提前四十八小时</a-radio>
                    </a-radio-group>
                </a-form-item>
            </a-form>
        </div>
    </div>
</template>
<script>
export default {
    name:'educational',
    data(){
        return{
            loading:false,
            form: this.$form.createForm(this),
            rules: {
                lesson_can_cancel: ['lesson_can_cancel', { rules: [] }],
                lesson_cancel_need_costs: ['lesson_cancel_need_costs', { rules: [] }],
                lesson_alarm_teacher: ['lesson_alarm_teacher', { rules: [] }],
                lesson_alarm_student: ['lesson_alarm_student', { rules: [] }],
                lesson_cancel_before_hours: ['lesson_cancel_before_hours', { rules: [] }],
            },
        }
    },
    mounted(){
        this.getMessage();
    },
    methods:{
        async getMessage(){
                this.loading = true
                // let resa = await this.$store.dispatch('settingSystemMonitorAction', {})
                let res = await this.$store.dispatch('settingSystemEducationAction', {})
                let formData = {}
                res.data.forEach((item,index)=>{
                    formData[item.name] = Number(item.value)
                })
                delete formData.education_config
                this.form.setFieldsValue(formData)
                this.loading = false
        },
        async submitData(){
            this.loading = true
            let data = await this.form.validateFields();
            let res = await this.$store.dispatch('settingSystemEducationUpdateAction', data)
            if(res.code ==200){
                this.$message.success('操作成功!')
            }else{
                this.$message.error('操作失败!')
            }
            this.loading=false;
        }
    }
}
</script>