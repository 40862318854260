<template>
    <div class="zl-list createChannel">
        <div class="title flts-title">
            <h3>招生渠道设置</h3>
            <a-button type="primary" @click="showModal">新增分类</a-button>
            <a-button type="dashed" icon="download" @click="toExport" :loading="exportLoading">渠道导出</a-button>
        </div>
        <div>
            <a-input v-model="inpValue" style="width:300px;margin-right:10px" placeholder="搜索渠道名称" />
            <a-button @click="getList" type="primary">筛选</a-button>
        </div>
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <tree v-if="reFresh" :data='treeData' :filter="inpValue" :options="treeOptions" ref="sourceTree">
                <div slot-scope="{ node }" class="node-container">
                    <div class="node-text">
                        <div v-if="!node.parent">
                            <!-- <div v-if="!node.parent" style="width:20px;height:20px"> -->
                            <a-dropdown>
                                <div class="ant-dropdown-link" @click="e => e.preventDefault()">
                                    <a-badge v-if="node.data.channel_level == 1" status="default" :text="node.text" />
                                    <a-badge v-if="node.data.channel_level == 3" status="warning" :text="node.text" />
                                    <a-badge v-if="node.data.channel_level == 5" status="error" :text="node.text" />
                                </div>
                                <div class="node-level" slot="overlay">
                                    <div @click="changeLevel(node,item)" v-for="(item,index) in levelList" :style="{color:index == 1?'#fff':item.color,background:index == 1?item.color:'#fff'}" :key="index">{{item.name}}</div>
                                </div>
                            </a-dropdown>
                        </div>
                        <div v-else>
                         <a-popover :title="node.text">
                            <template slot="content">
                                <a-textarea style="width:420px;" placeholder="请输入渠道对应沟通技巧。" :value="node.data.tips" :autoSize="{ minRows: 6 }" allowClear @change="onChange($event,node)"/>
                            </template>
                            <a-icon type="bulb" theme="twoTone" two-tone-color="#faad14" />
                         </a-popover>
                        {{node.text}}
                        </div>
                    </div>
                    <div class="node-text">({{node.data.count}})</div>
                    <div @click.stop class="node-controls">
                        <a-icon style='fontSize:20px' type="form" @mouseup.stop="editNode(node)"/>
                        <a-icon style='fontSize:20px' type="close-square" @mouseup.stop="removeNode(node)"/>
                        <a-icon v-if="!node.parent" style='fontSize:20px' type="plus-square" @mouseup.stop="addChildNode(node)"/>
                    </div>
                </div>
            </tree>
        </div>
        <a-modal title="新增渠道分类" v-model="visible" :confirmLoading="confirmLoading"
            cancelText="取消" okText="确定" width="400px"
            @ok="addRandomNode" @cancel="handleCancel">
            <a-form :form="form">
              <a-form-item label="渠道分类名称">
                  <a-input v-decorator="['channel_name', { rules: [{ required: true, message: '请输入渠道分类名称!' }] }]" placeholder="请输入渠道分类名称" />
              </a-form-item>
            </a-form>
        </a-modal>
    </div>
</template>
<script>
const eventsList = [
        { name: 'tree:mounted', args: ['Tree Component'] },
        { name: 'tree:filtered', args: ['Matches', 'Filter String'] },
        { name: 'tree:data:fetch', args: ['Parent Node'] },
        { name: 'tree:data:received', args: ['Parent Node'] },
        { name: 'node:disabled', args: ['Node']},
        { name: 'node:enabled', args: ['Node']},
        { name: 'node:shown', args: ['Node'] },
        { name: 'node:hidden', args: ['Node'] },
        { name: 'node:dblclick', args: ['Node'] },
        { name: 'node:selected', args: ['Node'] },
        { name: 'node:unselected', args: ['Node'] },
        { name: 'node:checked', args: ['Node', 'Target Node'] },
        { name: 'node:unchecked', args: ['Node'] },
        { name: 'node:expanded', args: ['Node'] },
        { name: 'node:collapsed',  args: ['Node'] },
        { name: 'node:added',  args: ['Node', 'New Node'] },
        { name: 'node:removed',  args: ['Node'] },
        { name: 'node:text:changed', args: ['Node', 'New Text', 'Old Text']},
        { name: 'node:editing:start',  args: ['Node'] },
        { name: 'node:editing:stop',  args: ['Node', 'isTextChanged'] },
      ]

export default {
    name:'createChannel',
    data(){
        return{
            events: [],
            treeData:[],
            treeOptions:{
                propertyNames: {
                    text: 'channel_name',
                    id:'channel_id'
                },
                dnd: {
                    onDragStart(node) {
                        return node.data.isNotDraggable !== true
                    },
                    onDragOn:this.onDragOn,
                    onDragFinish:this.onDragFinish
                },

            },
            inpValue:'',
            reFresh: false,
            loading: false,
            exportLoading: false,
            firstIn:1,
            visible:false,
            confirmLoading:false,
            form: this.$form.createForm(this),
            levelList:[
                {name:'高',color:'#F5222D',value:5},
                {name:'中',color:'#FAAD14',value:3},
                {name:'低',color:'#D9D9D9',value:1}
            ]
        }
    },
    created(){
        this.getList()
    },

    computed: {
        eventsList() {
            return this.events.concat().reverse()
        }
    },
    updated(){
        if(this.reFresh){
            if(this.firstIn == 1){
            eventsList.forEach(e => {
                this.$refs.sourceTree.$on(e.name, this.initEventViewer(e))
            })
            this.firstIn++
            }
        }
    },
    methods:{
        async onChange(e,node) {
            let obj= {
                channel_id:node.id,
                tips:e.target.value
            }
            let res = await this.$store.dispatch('cChannelUpdateAction', obj)
            if(res.code == 200){
             //   this.$message.success('操作成功!')
            }
        },
        async getList(){
            this.loading = true
            let res = await this.$store.dispatch('cChannelAction', {})
            this.treeData = res.data
            res.data.forEach((item)=>{
                item.data.isNotDraggable = true
            })
            this.loading = false          
            if(this.reFresh ){
                this.$refs.sourceTree.updateData()
            }else{
                this.reFresh = true
            }
        },
        editNode(node, e) {
            node.startEditing()
        },
        removeNode(node) {
            let that = this
            let obj = {channel_id: node.id }
            this.$confirm({
                title: `您确定删除该渠道？`,
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                return new Promise(async (resolve, reject) => {
                    let res = await that.$store.dispatch('cChannelDeleteAction', obj)
                    resolve(res)
                    if(res.code == 200){
                        that.$message.success('操作成功!')
                        node.remove()
                    }else{
                        that.$message.error('操作失败!')
                    }
                }).catch(error => console.log(error))
                }
            })
        },
        async addRandomNode(){
            const { form } = this
            let params = await form.validateFields()
            let obj= {
                parent_id:0,
                channel_name:params.channel_name
            }
            this.confirmLoading = true
            await this.$store.dispatch('cChannelAddAction', obj)
            .then(res=>{
                // this.$refs.sourceTree.append(obj.channel_name)
                // this.$refs.sourceTree.updateData(obj.channel_name, node => {
                //     console.log(node)
                //     node.id = res.data.channel_id
                //     node.data.count = 0
                //     form.resetFields()
                // })
                this.reFresh = false
                this.getList()
                this.$message.success('操作成功!')
                this.visible = false
                this.confirmLoading = false
            })
            .catch(err=>{
                this.confirmLoading = false
            })
        },
        async addChildNode(node) {
            if (node.enabled()) {
                let obj= {
                parent_id:node.id,
                channel_name:`${node.text}-子渠道-${node.children.length}`
                }
                this.loading = true
                let res = await this.$store.dispatch('cChannelAddAction', obj)
                if(res.code == 200){
                    node.append(obj.channel_name)
                    var arr = node.children;
                    node.children[arr.length - 1].id = res.data.channel_id;
                    node.children[arr.length - 1].data.count = 0
                    node.data.count = node.children.length
                    this.$message.success('操作成功!')
                }
                this.loading = false
            }
        },
        async toEditing(node){
            let obj= {
                channel_id:node.id,
                channel_name:node.text
            }
            let res = await this.$store.dispatch('cChannelUpdateAction', obj)
            if(res.code == 200){
                this.$message.success('操作成功!')
            }
        },
        initEventViewer(event) {
            var that = this;
            const events = this.events
            return function (node, newNode) {
              if(event.name == 'node:editing:stop'){
                  that.toEditing(node);
              }

            }
        },
        onDragOn(targetNode, destinationNode, dropPosition){
            if(dropPosition == 'drag-on'){
                if(destinationNode.parent){
                    destinationNode.data.isNotDroppable = true
                }else{
                    destinationNode.data.isNotDroppable = false
                }
            }else{
                if(destinationNode.parent){
                    destinationNode.data.isNotDroppable = false
                }else{
                    destinationNode.data.isNotDroppable = true
                }
            }
            return destinationNode.data.isNotDroppable !== true
        },
        onDragFinish(targetNode, destinationNode, dropPosition) {
            if(dropPosition == 'drag-on'){
                if(destinationNode.parent){
                    this.$message.warning('不能移动!')
                    destinationNode.data.isNotDroppable = true
                }else{
                    destinationNode.data.isNotDroppable = false
                    let obj = {
                        channel_id:targetNode.id,
                        move_to_channel_id:destinationNode.id
                    }
                    let res = this.$store.dispatch('cChannelMoveToAction', obj)
                    this.$message.success('操作成功!')
                }
            }else{
                if(dropPosition){
                    if(destinationNode.parent){
                        destinationNode.data.isNotDroppable = false
                        let obj = {
                            channel_id:targetNode.id,
                            move_to_channel_id:destinationNode.parent.id
                        }
                        let res = this.$store.dispatch('cChannelMoveToAction', obj)
                        this.$message.success('操作成功!')
                        
                    }else{
                        this.$message.warning('不能移动成!')
                        destinationNode.data.isNotDroppable = true
                    }
                }else{
                    this.$message.warning('不能移动!')
                    destinationNode.data.isNotDroppable = true
                }
                
            }
            return destinationNode.data.isNotDroppable !== true
        },
        async toExport(){
            this.exportLoading = true
            let exportData = {}
            let res = await this.$store.dispatch('cChannelExpoortAction', exportData)
            const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
            if ('download' in document.createElement('a')) { 
                const link = document.createElement('a')
                link.download = `渠道导出.xlsx`
                link.style.display = 'none'
                link.href = URL.createObjectURL(blob)
                document.body.appendChild(link)
                link.click()
                URL.revokeObjectURL(link.href) 
                document.body.removeChild(link)
            } else { //其他浏览器
                navigator.msSaveBlob(blob, fileName)
            }
            this.exportLoading = false
        },
        async changeLevel(node,item){
            if(node.data.channel_level != item.value){
                await this.$store.dispatch('cChannelLevelAction', {data:{channel_id:node.data.channel_id,level:item.value}})
                .then(res=>{
                    // this.loading = true
                    // this.reFresh = false
                    // this.getList()
                    node.data.channel_level = item.value
                    this.$message.success('操作成功')
                })
                .catch(err=>{
                    this.$message.error('操作失败')
                })
            }
        },
        showModal(){
            this.visible = true
        },
        handleCancel(){
            this.visible = false
        }
    }
}
</script>
<style lang="scss">
    .createChannel{
        .node-container{
            display: flex;
        }
        .node-text{
            margin-right: 10px;
        }
        .node-controls{
          display: flex;
          align-items:  center;
          justify-content: center;
            i{
                margin-right: 10px;
            }
        } 
    }
    .node-level{
        width: 112px;
        display: flex;
        background: #fff;
        padding: 5px;
        border-radius: 2px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
        div{
            margin: 0 5px;
            border: 1px solid;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            cursor: pointer;
        }
    }
    .zl-list{
        .flts-title{
            display: -webkit-box;
        }
    }
</style>