<template>
    <div class="zl-list">
        <div class="title flts-title">
            <h3>客户标签</h3>
            <div>
                <a-button @click="showEditModal()" icon="plus">添加标签组</a-button>
                <a-button icon="cloud-sync" @click="toSync" :loading="syncLoading" style="margin-left:10px" type="dashed">同步企微标签</a-button>
            </div>
        </div>
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-list item-layout="horizontal" :data-source="list">
                <a-list-item slot="renderItem" slot-scope="item, index">
                    <a-row type="flex" style="width:100%" :gutter="10">
                        <a-col :span="3">
                            <div style="word-break: break-all">
                                {{item.group_name}}
                            </div>
                        </a-col>
                        <a-col :span="17">
                            <div>
                                <div>
                                    <a-tag style="margin:5px" v-for="(it,ind) in item.tag_list" :key="ind">{{it.tag_name}}</a-tag>
                                    <a-input
                                    v-if="item.tag_list_show"
                                    ref="input"
                                    type="text"
                                    size="small"
                                    :maxLength="30"
                                    :style="{ width: '78px' }"
                                    :value="inputValue"
                                    @change="handleInputChange"
                                    @blur="handleInputConfirm"
                                    @keyup.enter="handleInputConfirm"
                                    />
                                    <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput(item,index)">
                                    <a-icon type="plus" /> New Tag
                                    </a-tag>
                                </div>
                            </div>
                        </a-col>
                        <a-col style="display:flex" :span="3">
                            <div style="cursor: pointer;" @click="showEditModal(item)">
                                <a-icon type="form" />
                                <span style="margin-left:5px">修改</span>
                            </div>
                            <div style="cursor: pointer;margin-left:10px" @click="toDel(item)">
                                <a-icon type="delete" />
                                <span style="margin-left:5px">删除</span>
                            </div>
                        </a-col>
                    </a-row>
                </a-list-item>
            </a-list>
        </div>
        <editModal v-if="isEditModal" :item="modalData" />
    </div>
</template>

<script>
    import editModal from './editModal'
    export default {
        name:"customerTag",
        data() {
            return {
                list: [],
                inputValue:'',
                inputIndex:-1,
                loading:false,
                syncLoading:false,
                isEditModal:false,
            }
        },
        provide() {
            return {
                parent: this
            }
        },
        components: {
            editModal,
        },
        created () {
            this.getList()
        },
        methods: {
            async getList(){
                this.loading = true
                await this.$store.dispatch('settingWxworkTagGroupAction',{})
                .then(res=>{
                    this.list = res.data
                    this.loading = false
                })
            },
            async toSync(){
                this.syncLoading = true
                await this.$store.dispatch('settingWxworkTagSyncAction',{})
                .then(res=>{
                    this.syncLoading = false
                    this.$message.success('操作成功！！')
                    this.getList()
                })
                .catch(err=>{

                })
            },
            showInput(item,index) {
                item.tag_list_show = true
                this.$nextTick(function() {
                    this.$refs.input.focus()
                })
                this.inputIndex = index
            },
            handleInputChange(e) {
                this.inputValue = e.target.value
            },
            async handleInputConfirm() {
                const inputValue = this.inputValue
                if(!inputValue){
                    this.list[this.inputIndex].tag_list_show = false
                    this.inputValue = ''
                    return false
                }
                let tag_list_name = this.list[this.inputIndex].tag_list.map(it=>{return it.tag_name})
                if (tag_list_name.indexOf(inputValue) === -1) {
                    let obj = {
                        group_id: this.list[this.inputIndex].group_id,
                        group_name: this.list[this.inputIndex].group_name,
                        tag_list:[{ tag_name:inputValue, id:'', tag_id:''}]
                    }
                    await this.$store.dispatch('settingWxworkAddTagsAction',{data:obj})
                    .then(res=>{
                        this.$message.success('操作成功！！')
                        this.list[this.inputIndex].tag_list_show = false
                        this.inputValue = ''
                        this.getList()
                    })
                    .catch(err=>{
                        this.$message.error('操作失败！！')
                    })
                }else{
                    this.$message.warning('标签名称重复！！')
                    this.list[this.inputIndex].tag_list_show = false
                    this.inputValue = ''
                }
            },
            showEditModal(item){
                this.modalData = item
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val){
                    this.getList()
                }
                this.isEditModal = false
            },
            toDel(item){
                let that = this
                this.$confirm({
                title: `确定要删除该标签组?`,
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                    return new Promise(async (resolve, reject) => {
                    await that.$store.dispatch('settingWxworkDelTagsAction', { data: { group_id_list: [item.group_id]}})
                    .then(res=>{
                        that.$message.success('操作成功！！')
                        that.getList()
                        resolve(res)
                    })
                    }).catch(error => console.log(error))
                }
                })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>