<template>
  <div @click.stop style="position: fixed!important;" class="qrmodal">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <img style="width: 350px;border-radius: 20px;" src="@/assets/wechat_qrcode_pay.png">
      <div class="qrmodal-img" id="qrcode" ref="qrcode"></div>
  </div>
</template>

<script>
    import QRCode from 'qrcodejs2'

    export default {
        name:'qrModal',
        inject: ['parent'],
        props:{
            item:Object,
        },
        data() {
            return {
                loading: false,
                qrcode:'',
                out_trade_no:'',
            }
        },
        created(){
            this.getQrcode()
        },
        methods: {
            async getQrcode(){
                this.loading = true
                let res = await this.$store.dispatch('settingSystemSmsPayAction',{product_id:this.item.product_id})
                this.qrcode = res.data.qrcode_scan
                this.out_trade_no = res.data.out_trade_no
                this.getLongpoll(res.data.out_trade_no)
                this.loading = false
            },
            async getLongpoll(val){
                let res = await this.$store.dispatch('settingSystemLongpollAction',{out_trade_no:val})
                if(res.data.success == 1){
                    await this.$store.dispatch('accoutAction', {})
                    this.parent.hideQrModal(1)
                    this.$message.success('支付成功~')
                }else if(res.data.success == 0 && this.parent.isQrModal){
                    this.getLongpoll(val)
                }
            }
        },
        updated () {
            this.$nextTick(() => {
                var canvas = this.$refs.qrcode
                new QRCode(canvas, {
                    text:this.qrcode,
                    width:200,
                    height:200,
                    colorDark : "#000",   //二维码颜色
                    colorLight : "#ffffff"   //二维码背景色
                })
            })
        },
    }
</script>

<style lang="scss">
    .qrmodal{
        position: fixed!important;
        left: 50%;
        top: 100px;
        margin-left: -175px;
        z-index: 4100;
        &-img{
            width: 200px;
            height: 200px;
            background: rgba(12,122,24,0.4);
            position: absolute;
            top: 231px;
            left: 75px;
        }
    }
</style>