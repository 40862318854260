<template>
  <a-modal :title="modalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="500px"
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-form :form="form">
        <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label">
        <a-select v-if="item.type === 'select'" placeholder="请选择" :disabled="isEdit?true:false"
           v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-select v-if="item.type === 'selects'" placeholder="请选择" mode="multiple"
           v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>

const formData = [
   {
    name: 'employee_id',
    label: '通知员工',
    type: 'select',
    rules: [{ required: true, message: '请选择员工!' }],
    items: {
      data: 'employees',
      label: 'filter_name',
      value: 'employee_id'
    }
  },
  {
    name: 'notify_studio_ids',
    label: '通知校区',
    type: 'selects',
    rules: [{ required: true, message: '请选择通知校区' }],
    items: {
      data: 'studios',
      label: 'filter_name',
      value: 'studio_id'
    }
  },
]

export default {
  name: 'EditModal',
  inject: ['parent'],
  data() {
    return {
      loading:false,
      formData,
      seleteItems: {
        employees:[],
        studios: [],
      },
      modalTitle:'校区通知',
      confirmLoading:false,
      form: this.$form.createForm(this),
    }

  },
  props: {
    item: Object,
    isEdit: Number
  },
  async created() {
      this.visible = true
      this.getStudioList()
      this.getAccountList()
      if(this.item){
        this.loading = true
      }
      await this.$nextTick()
      if(this.item){
        let obj = {
          employee_id:this.item.employee_id,
          notify_studio_ids:this.item.notify_studio_ids
        }
        this.form.setFieldsValue(obj)
      }
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getStudioList() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.seleteItems.studios = res.data
    },
    async getAccountList() {
      let res = await this.$store.dispatch('searchAccountEmployeeAction',{data:{active:true}})
      this.seleteItems.employees = res.data
      this.loading = false
    },
    async handleOk() {
      const { form } = this
      let params = await form.validateFields()
      this.confirmLoading = true
      if(this.item){
        params.notice_id = this.item.notice_id
      }
      await this.$store.dispatch(this.item?'settingStudioNoticeUpdateAction':'settingStudioNoticeAddAction', {data: params})
      .then(res=>{
        this.$message.success('操作成功~')
        this.confirmLoading = false
        this.parent.hideEditModal(1)
      })
      .catch(err=>{
        this.$message.success('操作失败~')
        this.confirmLoading = false
      })
    },
    handleCancel(){
      this.parent.hideEditModal()
    },
  }
}
</script>