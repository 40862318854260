<template>
    <div class="bizParam">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>通用设置</a-breadcrumb-item>
            <a-breadcrumb-item>系统设置</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="main-box bizParam-box">
          <div style="width:200px;height:800px;overflow: auto;">
            <a-menu :selectedKeys="keyPath" @click="handleClick">
              <a-menu-item :id="`bizParam-menu-systemInfo`" key="1">系统信息</a-menu-item>
              <a-menu-item :id="`bizParam-menu-educational`" key="2">教务系统</a-menu-item>
              <a-menu-item :id="`bizParam-menu-educational`" key="19">约课设置</a-menu-item>
              <a-menu-item :id="`bizParam-menu-campusNotice`" key="4">校区通知</a-menu-item>
              <a-menu-item :id="`bizParam-menu-payNotice`" key="7">支付通知</a-menu-item>
              <a-menu-item :id="`bizParam-menu-IntegralSetting`" key="9">积分设置</a-menu-item>
              <!-- <a-menu-item key="3">预约申请</a-menu-item> -->
              <a-menu-item :id="`bizParam-menu-agreement`" key="10">在线协议</a-menu-item>
              <a-menu-item :id="`bizParam-menu-wexinPay`" key="3">支付设置</a-menu-item>

              
              <a-menu-item :id="`bizParam-menu-SMSService`" key="5">
                <a-badge :dot="app_sms_count < 100">
                  <span>短信服务</span>
                  </a-badge>
              </a-menu-item>
             
              <a-menu-item :id="`bizParam-menu-financeSection`" key="18">财务期间设置</a-menu-item>
              <!-- <a-menu-item key="8">焦点图设置</a-menu-item> -->
              
              <a-menu-item :id="`bizParam-menu-createChannel`" key="12">招生渠道设置</a-menu-item>

              <a-menu-item :id="`bizParam-menu-templateMessage`" key="13">模板消息设置</a-menu-item>
              <!-- <a-menu-item key="11">跟进记录类型设置</a-menu-item> -->
              <a-menu-item :id="`bizParam-menu-contractExpireSet`" key="14">招生规则设置</a-menu-item>


              <a-menu-item :id="`bizParam-menu-wexinAuthorization`" key="15">微信公众号授权</a-menu-item>

              <a-menu-item :id="`bizParam-menu-enterpriseWechat`" key="16">企业微信授权</a-menu-item>
              <a-menu-item :id="`bizParam-menu-customerTag`" key="17">企微客户标签</a-menu-item>
              
              <!-- <a-menu-item key="16">锁定客户数限制</a-menu-item> -->
            </a-menu>
          </div>
          <div style="flex:1;padding-left:25px">
            <systemInfo v-if="keyPath[0] === '1'" />
            <educational v-if="keyPath[0] === '2'" />
            <wexinPay v-if="keyPath[0] === '3'"/>
            <campusNotice v-if="keyPath[0] === '4'" />
            <SMSService v-if="keyPath[0] === '5'" />
            <cftz v-if="keyPath[0] === '7'" />
            <banner v-if="keyPath[0] === '8'" />
            <IntegralSetting v-if="keyPath[0] === '9'" />
            <xyxy v-if="keyPath[0] === '10'" />
            <FollowLogTypeSet v-if="keyPath[0] === '11'" />
            <createChannel v-if="keyPath[0] === '12'" />
            <templateMessage v-if="keyPath[0] === '13'" />
            <contractExpireSet v-if="keyPath[0] === '14'" />
            <wexinAuthorization v-if="keyPath[0] === '15'" />
            <enterpriseWechat v-if="keyPath[0] === '16'" />
            <customerTag v-if="keyPath[0] === '17'" />
            <financeSection v-if="keyPath[0] === '18'" />
            <appointment v-if="keyPath[0] === '19'" />

          </div>
        </div>
    </div>
</template>

<script>
import banner from '../../orginization/studio/jdtgl'
import cftz from './modal/zftz'
import xyxy from './modal/xyxy'
import educational from './modal/educational'
import systemInfo from './modal/systemInfo'
import createChannel from './modal/createChannel'
import wexinPay from './modal/wexinPay'
import templateMessage from './modal/templateMessage'
import campusNotice from './modal/campusNotice'
import SMSService from './modal/SMSService/index'
import IntegralSetting from './modal/IntegralSetting'
import FollowLogTypeSet from './modal/FollowLogTypeSet'
import contractExpireSet from './modal/contractExpireSet/index'
import wexinAuthorization from './modal/wexinAuthorization'
import enterpriseWechat from './modal/enterpriseWechat'
import customerTag from './modal/customerTag'
import financeSection from './modal/financeSection'
import appointment from './modal/appointment'

export default {
  name: 'bizParam',
  provide() {
    return {
      parent: this
    }
  },
  components: {
      xyxy,
      cftz,
      banner,
      wexinPay,
      templateMessage,
      educational,
      systemInfo,
      SMSService,
      campusNotice,
      createChannel,
      IntegralSetting,
      FollowLogTypeSet,
      contractExpireSet,
      wexinAuthorization,
      enterpriseWechat,
      customerTag,
      financeSection,
      appointment
  },
  created () {
    if(this.$route.query.activeKey){
      this.keyPath = [`${this.$route.query.activeKey}`]
    }
  },

  data() {
    return {
        tabPosition: 'left',
        keyPath:['1'],
    }
  },
  computed: {
    app_sms_count(){
      return this.$store.getters.brand_info.app_sms_count
    }
  },
  methods:{
    handleClick(e) {
      this.keyPath = e.keyPath
    },
  }

}
</script>
<style lang="scss">
  .bizParam{
    .main-box{
      .ant-tabs-tab{
        text-align: left;
      }
      .ant-menu-light{
        border-right: 2px solid #e8e8e8;
        
      }
      .ant-menu-item:hover{
        background-color: #e6fff6;
      }
    }
    .bizParam-box{
      display: flex;
    }
    .ant-form-item{
      margin-bottom: 12px;
    }
    .ant-menu-item-selected{
      box-shadow: 2px 0px 0px #00cca2
    }
  }
</style>