<template>
  <div class="zl-list">
    <div class="title flts-title">
        <h3>校区通知</h3>
        <a-button @click='showEditModal()' type="primary">新增</a-button>
    </div>
    <div class="dashboard">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-card title="消息通知场景">
        <div class="text">
          <div>1. 校区快捷支付提醒。</div>
          <div>2. 校区课程预约提醒。</div>
          <div>3. 校区课程请假提醒。</div>
          <div>4. 校区在线申请课程试听提醒。</div>
          <div>5. 校区日报周报推送提醒，日报推送时间每日21:00，周报推送时间每周日21:00。</div>
        </div>
      </a-card>
      <a-card>
        <!-- <div class="clearfix table-tools">
          <div class="buttons">
              <a-form layout='inline'>
              <a-form-item>
              </a-form-item>
              </a-form>
          </div>
        </div> -->
        <div @click.stop class="table table-td">
                <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
                <a-table size="small" :pagination="false" :bordered='false' rowKey="notice_id"
                    :columns="columns" :dataSource="list">
                    <template slot="index" slot-scope="text, record , index">
                        <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                    </template>
                    <template slot="wechat" slot-scope="text, record">
                      <svg style="width: 12px;height: 12px;margin-left: 5px;" aria-hidden="true">
                        <use v-show="record.employee_wechat == 1" xlink:href="#icon-weixin"></use>
                        <use v-show="record.employee_wechat == 0" xlink:href="#icon-weixin1"></use>
                      </svg>
                    </template>

                     <template slot="studio_names" slot-scope="text, record">
                        <span v-if="record.studio_names.length === 0">--</span>
                        <a-dropdown v-else>
                            <div style="cursor:pointer" class="ant-dropdown-link" @click="e => e.preventDefault()">
                                {{record.studio_names[0]}}等{{record.studio_names.length}}个校区<a-icon type="caret-down" />
                            </div>
                            <a-menu style="max-height:400px;overflow-y: auto;" slot="overlay">
                                <a-menu-item style="text-align: center" v-for="(it,j) in record.studio_names" :key="j">
                                    {{it}}
                                </a-menu-item>
                            </a-menu>
                        </a-dropdown>
                    </template>

                    <template slot="studio" slot-scope="text,record">
                        <!-- <div>{{text | filterStudio}}</div> -->
                        <div v-for="(item, index) of record.notify_studio" :key="index">{{ item.studio_name }}</div>
                    </template>
                    <template slot="action" slot-scope="text,record">
                      <a @click="showEditModal(record)" href="javascript:;">变更</a>
                      <a-divider type="vertical" />
                      <a @click="deleteNotice(record)" href="javascript:;">删除</a>
                    </template>

                </a-table>
            </div>
            <div class="page">
                <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                >
                    <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                    </template>
                </a-pagination>
            </div>
      </a-card>
    </div>
    <editModal :item='modalData' :isEdit="isEdit" v-if="isEditModal" />
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'task_id',fixed: 'left'},
  {title: '通知账号', dataIndex: 'employee_name', key: 'employee_name'},
  {title: '',width: 40, dataIndex: 'employee_wechat', key: 'employee_wechat',scopedSlots: { customRender: 'wechat' }},
  {title: '通知校区', width:'500px', dataIndex: 'notify_studio', key: 'notify_studio',align:"center",scopedSlots: { customRender: 'studio_names' }},
  { title: '操作时间',dataIndex: 'datetime', key: 'datetime', align:'right'},
  { title: '操作', key: 'operation', scopedSlots: { customRender: 'action' },align:'right'}
]
import tableMixins from '@/common/mixins/table'
import editModal from './editModal'
export default {
  name: 'campusNotice',
  data() {
    return {
      columns,
      loading: false,
      employeeId: '',
      employees: [],
      list: [],
      studios:[],
      studio_id:'',
      isEditModal:false,
      isEdit:0,
    }
  },
  components: {
    editModal,
  },
  mixins: [ tableMixins ],
  filters: {
    filterStudio: function(arr) {
      let studio = []
      arr.forEach(item=>{
        studio.push(item.studio_name)
      })
      return studio.join(',')
    }
  },
  created() {
    
  },
  methods: {
    async getList() {
      this.loading = true
      let res = await this.$store.dispatch('settingStudioNoticeAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    async deleteNotice(item) {
      let that = this
      this.$confirm({
        title: `确定要删除账号"${item.employee_name}"?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('settingStudioNoticeDeleteAction', { data: { notice_id: item.notice_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    handleAccountSearch(val){
      let obj = {q:val}
      this.getAccountList(obj)
    },
    handleSearch(val){
      let obj = {q:val}
      this.getStudioList(obj)
    },
    showEditModal(item){
      if(item){
        this.isEdit = 1
      }else{
        this.isEdit = 0
      }
      this.modalData = item
      this.isEditModal = true
    },
    hideEditModal(val){
      if(val){
        this.getList()
      }
      this.isEditModal = false
    },
  }
}
</script>
