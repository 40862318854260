<template>
    <div class="zl-list">
        <div class="title flts-title">
            <h3>系统信息</h3>
            <a-button type="primary" @click="submitData"  :loading='loading'>保存</a-button>
        </div>
        <div style="height:650px;overflow:auto;">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-form :form="form" layout='vertical'>
                <a-form-item label="机构图片" >
                <div style="display: inline-block; position: relative;" @click="showImagesModal('app_avatar')">
                    <div v-if="imageUrl">
                    <LImg :src="imageUrl" style="width: 100px; height: 100px;"/>
                    <div class="close" @click="closeImg($event)">&times;</div>
                    </div>
                    <a-button v-else type="dashed" icon="file-image">选择图片</a-button>
                </div>
                </a-form-item>
                <a-form-item label="机构简称" >
                <a-input style="width:300px" v-decorator="rules.app_name"/>
                </a-form-item>
                <a-form-item label="机构全称" >
                <a-input style="width:300px" v-decorator="rules.org_name"/>
                </a-form-item>
                <a-form-item label="联系人姓名" >
                <a-input style="width:300px" v-decorator="rules.contact_name"/>
                </a-form-item>
                <a-form-item label="手机号" >
                <a-input style="width:300px" disabled v-decorator="rules.cellphone"/>
                </a-form-item>
                <!-- <a-form-item label="访问模式" > -->
                <!-- <a-select v-decorator="rules.phone_validate" placeholder="请选择">
                    <a-select-option :value="0">任何人可见</a-select-option>
                    <a-select-option :value="1">手机号码验证可见</a-select-option>
                </a-select> -->
                <!-- <a-radio-group v-decorator="['phone_validate']">
                    <a-radio :value="0">任何人可见</a-radio>
                    <a-radio :value="1">手机号码验证可见</a-radio>
                </a-radio-group> -->
                <!-- </a-form-item> -->
            </a-form>
        </div>
        <ImageModal v-if="imagesVisible"/>
    </div>
</template>
<script>
import url from '@/utils/URL'
import ImageModal from '@/views/modal/images'
export default {
    name:'systemInfo',
    props: {
        item: Object
    },
    provide() {
        return {
            parent: this
        }
    },
    components: {
        ImageModal,
    },

    data() {
        return {
            loading:false,
            form: this.$form.createForm(this),
            rules: {
                app_name: ['app_name', { rules: [{ required: true, message: '请输入机构简称!' }] }],
                org_name: ['org_name', { rules: [{ required: true, message: '请输入机构全称!' }] }],
                contact_name: ['contact_name', { rules: [{ required: true, message: '请输入联系人姓名!' }] }],
                cellphone: ['cellphone', { rules: [{ required: true, message: '请输入手机号码!' }] }],
                phone_validate: ['phone_validate', { rules: [] }],
            },
            imageUrl: '',
            imageRoot: url.imageRoot,
            uploadParams: {},
            imagesVisible: false,
            imageKey: '',
        }
    },
    created() {
        this.getList()
    },

    methods: {
        async getList() {
            this.loading = true
            let res = await this.$store.dispatch('settingSystemAction', {})
            this.detail = res.data
            this.imageUrl = this.detail['app_avatar']
            delete this.detail.app_avatar
            delete this.detail.email
            this.form.setFieldsValue(this.detail)
            this.loading = false
        },

        async submitData() {
            this.loading = true
            try {
                let data = await this.form.validateFields()
                data.app_avatar = this.imageUrl
                let res = await this.$store.dispatch('settingSystemAction', {data})
                if (res) {
                this.$message.success('保存成功！')
                }
            } catch {
            }
            this.loading = false
        },

        closeImg(e) {
        e.stopPropagation()
        this.imageUrl = ''
        },
        
        showImagesModal(imageKey) {
        this.imageKey = imageKey
        this.imagesVisible = true
        },
        hideImagesModal(image) {
        if (image) {
            this.imageUrl = image.path
        }
        this.imagesVisible = false
        },
    }
}
</script>
<style lang="scss">
.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>