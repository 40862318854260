<template>
  <a-modal :title="modalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="500px"
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-form :form="form">
        <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label">
        <a-select v-if="item.type === 'select'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption">
           <a-select-option v-for="(d, index) of yearList" :key="index" :value="d">{{ d }}</a-select-option>
        </a-select>
        <!-- <a-radio-group v-if="item.type === 'radio'" @change="handleRadio" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
          <a-radio value="0">自然月</a-radio>
          <a-radio value="1">非自然月</a-radio>
        </a-radio-group > -->
      </a-form-item>
    </a-form>
    <!-- <div v-if="radioValue === '1'">
      <span>从：上月</span>
      <a-select style="width:100px" placeholder="请选择" :value="month" @change="handleMonth">
          <a-select-option v-if="d != 1" v-for="(d, index) of 31" :key="index" :value="d">{{ d }}</a-select-option>
      </a-select>
      <span>号 至：本月{{month?month-1:'--'}}号</span>
    </div> -->
  </a-modal>
</template>

<script>

const formData = [
   {
    name: 'year',
    label: '年份',
    type: 'select',
    rules: [{ required: true, message: '请选择年份!' }],
  },
]
import moment from "moment"
export default {
  name: 'EditModal',
  inject: ['parent'],
  data() {
    return {
      loading:false,
      formData,
      yearList:[],
      modalTitle:'校区通知',
      confirmLoading:false,
      form: this.$form.createForm(this),
      radioValue:'0',
      month:''
    }

  },
  props: {
    item: Object
  },
  async created() {
      this.visible = true
      let defaultYear = 2017
      let nowYear = Number(moment().add(2, 'year').format('YYYY'))
      let yearList = [nowYear]
      for(let i=0; i <= nowYear-defaultYear; i++){
          nowYear-=1
          yearList.push(nowYear)
      }
      this.yearList = yearList
      await this.$nextTick()
      this.form.setFieldsValue({year:Number(moment().format('YYYY'))})
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async handleOk() {
      const { form } = this
      let params = await form.validateFields()
      this.confirmLoading = true
      await this.$store.dispatch('settingfinanceFiscalAction', {data: params})
      .then(res=>{
        this.$message.success('操作成功~')
        this.confirmLoading = false
        this.parent.hideEditModal(1)
      })
      .catch(err=>{
        this.$message.success('操作失败~')
        this.confirmLoading = false
      })
    },
    handleCancel(){
      this.parent.hideEditModal()
    },
    handleRadio(e){
      this.radioValue = e.target.value
    },
    handleMonth(e){
      this.month = e
    }
  }
}
</script>