<template>
    <div class="zl-list contractExpireSet">
        <div style="text-align:right">
            <a-button type="primary" @click="submitData" :loading='loading' >保存</a-button>
        </div>
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-form :form="form" layout='vertical'>
                <a-form-item label="合同到期提醒设置:" >
                    <a-radio-group @change="handleChange" name='contract_config' v-decorator="['contract_config']">
                        <div style="margin-bottom:12px">
                            <a-radio :value="0">不提醒</a-radio>
                        </div>
                        <div style="margin-bottom:12px">
                            <a-radio :value="1">提醒</a-radio>
                            提前 <a-input-number v-decorator="['contract_day']" :min='0' :disabled="contract_config == 0" style="width:120px" />
                             天提醒 或 少于多少 
                            <a-input-number v-decorator="['contract_less_then']" :min='0' :step="0.5" :disabled="contract_config == 0" style="width:120px" /> 课时提醒
                        </div>
                    </a-radio-group>
                </a-form-item>
                <a-form-item label="客户公海规则设置:" >
                    <a-radio-group @change="handleChange" name='remind_config' v-decorator="['remind_config']">
                        <div style="margin-bottom:12px">
                            <a-radio :value="0">不启用</a-radio>
                        </div>
                        <div style="margin-bottom:12px">
                            <a-radio :value="1">启用</a-radio>
                            <a-input-number v-decorator="['follow_day']" :min='0' :disabled="remind_config == 0" style="width:120px" />
                              天不跟进或 
                            <a-input-number v-decorator="['deal_day']" :min='0' :disabled="remind_config == 0" style="width:120px" />
                              天未成交 提前
                            <a-input-number v-decorator="['remind_day']" :min='0' :disabled="remind_config == 0" style="width:120px" /> 天进入客户公海提醒
                        </div>
                    </a-radio-group>
                </a-form-item>
                <!-- <a-form-item label="来源渠道变更:" >
                    <a-radio-group @change="handleChange" name='change_channel' v-decorator="['change_channel']">
                        <div style="margin-bottom:12px">
                            <a-radio :value="0">不可变更</a-radio>
                        </div>
                        <div style="margin-bottom:12px">
                            <a-radio :value="1">可变更</a-radio>
                        </div>
                    </a-radio-group>
                </a-form-item> -->
            </a-form>
        </div>
    </div>
</template>

<script>
    export default {
        name:'contractExpire',
        data() {
            return {
                loading: false,
                contract_config:1,
                remind_config:1,
                // change_channel:1,
                form: this.$form.createForm(this),
                rules:{
                    contract_config: ['contract_config', { rules: [] }],
                    remind_config: ['remind_config', { rules: [] }],
                    // change_channel: ['change_channel', { rules: [] }],
                    remind_day: ['remind_day', { rules: [] }],
                    contract_day: ['contract_day', { rules: [] }],
                    deal_day: ['deal_day', { rules: [] }],
                    follow_day: ['follow_day', { rules: [] }],
                    contract_less_then: ['contract_less_then', { rules: [] }],
                }
            }
        },
        created () {
            this.getConfig()
        },
        methods: {
            async getConfig(){
                let that = this
                this.loading = true;
                let res = await this.$store.dispatch('settingCrmConfigAction')
                let formData = {}
                res.data.forEach((item,index)=>{
                    formData[item.name] = Number(item.value)
                    if(item.name == 'contract_config'){
                        that.contract_config = Number(item.value)
                    }
                    if(item.name == 'remind_config'){
                        that.remind_config = Number(item.value)
                    }
                    // if(item.name == 'change_channel'){
                    //     that.change_channel = Number(item.value)
                    // }
                })
                delete formData.record_type
                this.form.setFieldsValue(formData)
                this.loading = false
            },
            handleChange(e){
                let name = e.target.name
                this[name] = e.target.value
            },
            async submitData() {
                this.loading = true
                let data = await this.form.validateFields()
                let res = await this.$store.dispatch('settingCrmConfigUpdateAction', data)
                if(res.code ==200){
                    this.$message.success('操作成功!')
                }else{
                    this.$message.error('操作失败!')
                }
                this.loading=false;
            }
        },
    }
</script>