<template>
  <a-modal :title="modalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="500px"
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-form :form="form">
        <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-input-number v-if="item.type === 'number'" v-decorator="[item.name, { rules: item.rules }]" style='width:100%' :min="0" />
        <a-select v-if="item.type === 'selects'" placeholder="请选择" mode="multiple"
           v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>

const formData = [
  {
    name: 'rule_name',
    label: '规则名称',
    type: 'input',
    rules: [{ required: true, message: '请输入规则名称!' }]
  },
  {
    name: 'rule_employee_ids',
    label: '适用范围',
    type: 'selects',
    rules: [{ required: true, message: '请选择员工!' }],
    items: {
      data: 'employees',
      label: 'filter_name',
      value: 'employee_id'
    }
  },
  {
    name: 'rule_value',
    label: '拥有客户上限',
    type: 'number',
    rules: [{ required: true, message: '请输入拥有客户上限!' }]
  }
]

export default {
  name: 'EditModal',
  inject: ['parent'],
  data() {
    return {
      loading:false,
      formData,
      seleteItems: {
        employees:[],
      },
      modalTitle:'添加规则',
      confirmLoading:false,
      form: this.$form.createForm(this),
    }

  },
  props: {
    item: Object,
  },
  async created() {
      this.visible = true
      this.getAccountList()
      if(this.item){
        this.loading = true
        await this.$nextTick()
        let obj = {
          rule_name:this.item.rule_name,
          rule_value:this.item.rule_value,
          rule_employee_ids:this.item.rule_employee_ids
        }
        this.form.setFieldsValue(obj)
      }
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getAccountList() {
      let res = await this.$store.dispatch('searchAccountEmployeeAction',{})
      this.seleteItems.employees = res.data
      this.loading = false
    },
    async handleOk() {
      const { form } = this
      let params = await form.validateFields()
      this.confirmLoading = true
      if(this.item){
        params.rule_id = this.item.rule_id
      }
      await this.$store.dispatch(this.item?'settingCrmCustomerConfigUpdateAction':'settingCrmCustomerConfigAddAction', params)
      .then(res=>{
        this.$message.success('操作成功！！')
        this.parent.hideEditModal(1)
      })
      .catch(err=>{
        this.confirmLoading = false
      })

    },
    handleCancel(){
      this.parent.hideEditModal()
    },
  }
}
</script>