<template>
  <div class="zl-list">
    <div class="title flts-title">
        <h3>支付通知</h3>
    </div>
    <div class="dashboard">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-card title="消息通知场景">
        <div class="text">
          任何在平台上产生的交易支付订单成功时消息通知
        </div>
      </a-card>
      <a-card>
        <div class="search">
          <a-form layout='inline'>
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>通知账号</span>
                </template>
                <a-select v-model="employeeId" :filterOption="filterOption" @search="handleAccountSearch" showSearch allowClear placeholder="请选择通知账号" style="width: 200px">
                  <a-select-option value="">请选择通知账号</a-select-option>
                  <a-select-option v-for="(d, index) of employees" :key="index" :value="d['employee_id']">{{ d['filter_name'] }}</a-select-option>
                </a-select>
              </a-tooltip>
            </a-form-item>
            <a-form-item>
              <a-button type="primary" @click="addNotice" :loading='loading' >添加</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="user-list" style="max-height:460px;overflow:auto;">
          <div class="item" v-for="(item, index) of list" :key="index">
            <div class="photo">
              <LImg :src="item.employee_avatar"/>
              <div class="delete" @click="deleteNotice(item)"><a-icon type="delete" /></div>
            </div>
            <div class="name">{{ item.employee_name }}</div>
            <!-- <div class="phone">{{ item.datetime }}</div> -->
          </div>
          <div style="width:100%;height:150px" v-if="list.length == 0">
            <l-Empty />
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Setting',

  data() {
    return {
      loading: false,
      employeeId: '',
      employees: [],
      list: []
    }
  },

  created() {
    this.getList()
    this.getAccountList()
  },
  methods: {
    async getList() {
      this.loading = true
      let res = await this.$store.dispatch('settingPaymentNoticeAction', { data: {} })
      this.list = res.items
      this.loading = false
    },
    async getAccountList(val) {
      let res = await this.$store.dispatch('searchAccountEmployeeAction', {params:{q:val},data:{active:true}})
      this.employees = res.data
    },
    async addNotice() {
      this.loading = true
      let data = {
        employee_id: this.employeeId, 
        ext_id: ''
      }
      let res = await this.$store.dispatch('settingPaymentNoticeAddAction', { data }).then(res=>{
        this.getList()
        this.$message.success('操作成功!')
      }).catch(res=>{
        this.loading=false;
      })
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    handleAccountSearch(val){
      this.getAccountList(val)
    },
    async deleteNotice(item) {
      let that = this
      this.$confirm({
        title: `确定要删除账号"${item.employee_name}"?`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('settingPaymentNoticeDeleteAction', { data: { notice_id: item.notice_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    }
  }
}
</script>
