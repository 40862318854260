<template>
    <div class="applink">
        <div class="conts">
            <div v-for="(item, index) of  list" :key="index" class="link-items">
                <div @click="toPage(item)" class="link-itemF">
                    <div class="icon">
                        <img :src="item.icon"/>
                        <div class="number"><a-badge :count="item.notice" /></div>
                    </div>
                    <div class="text">
                        <div class="name">{{ item.name }}</div>
                        <div class="sub">{{ item.desc }}</div>
                    </div>
                </div>
                <div v-if="!item.install" style="margin-top:5px">
                    <a-alert message="应用未安装,点击安装" type="warning" show-icon />
                </div>
            </div>
        </div>
  </div>
</template>

<script>
    export default {
        name:'wechat',
        data() {
            return {
                list: [],
            }
        },
        created () {
            if(this.$route.query.auth_code){
                this.sendCallBack()
            }else{
                this.getList()
            }
        },
        methods: {
            async getList() {
                this.loading = true
                let res = await this.$store.dispatch('settingWxworkInstallAction', { })
                this.list = res.data
                this.loading = false
            },
            async sendCallBack(){
                let obj = {
                    state:this.$route.query.state,
                    expires_in:this.$route.query.expires_in,
                    auth_code:this.$route.query.auth_code
                }
                let res = await this.$store.dispatch('settingWxworkCallbackAction', {data:obj})
                this.getList()
            },
            toPage(item){
                if(!item.install){
                    window.open(`${item.install_url}`)
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
  .applink{
    .sub{
      width: 150px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis
    }
    .conts {
        padding-bottom: 20px;
        margin: 0 -8px;
        .link-item {
        margin: 0 8px 16px;
        display: flex;
        padding: 11px;
        width: 228px;
        height: 68px;
        background: #f6f6f6;
        border-radius: 4px;
        cursor: pointer;
        &:hover {
            background-color: #f1f1f1;
        }
        }
        .icon {
        position: relative;
        margin-right: 10px;
        .number {
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate3d(15px, -12px, 0);
            z-index: 10;
            .ant-badge-count {
            height: 14px;
            line-height: 14px;
            padding: 0 5px;
            }
        }
        img {
            width: 46px;
            height: 46px;
        }
        }
        .name {
        margin: 0;
        font-size: 16px;
        color: #333;
        }
        .sub {
        font-size: 12px;
        color: #A3A3A3;
        line-height: 17px;
        }
    }
    .link-items {
      float: left;
      margin: 0 8px 16px;
      padding: 11px;
      background: #f6f6f6;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: #f1f1f1;
      }
    }
    .link-itemF{
        display: flex;
    }
  }
</style>