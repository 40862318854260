<template>
  <div class="zcxy">
    <div>
        <div class="clearfix table-tools">
        <div class="buttons">
            <a-form layout='inline'>
                <a-form-item>
                    <a-button type="primary" icon="plus" @click="showEditModal()">添加规则</a-button>
                </a-form-item>
            </a-form>
        </div>
        <div class="search"></div>
        </div>
        <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="rule_id"
            :columns="columns" :dataSource="list">
            <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
            </template>
            <template slot="rule_employee" slot-scope="text, record , index">
                <span v-if="record.rule_employee.length === 0">--</span>
                <a-dropdown v-else>
                    <div style="cursor:pointer" class="ant-dropdown-link" @click="e => e.preventDefault()">
                        {{record.rule_employee[0].employee_name}}等{{record.rule_employee.length}}个人<a-icon type="caret-down" />
                    </div>
                    <a-menu style="max-height:400px;overflow-y: auto;" slot="overlay">
                        <a-menu-item style="text-align: center" v-for="(it,j) in record.rule_employee" :key="j">
                            {{it.employee_name}}
                        </a-menu-item>
                    </a-menu>
                </a-dropdown>
            </template>
            <template slot="action" slot-scope="text,record">
                <a-dropdown>
                <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
                <a-menu slot="overlay">
                    <a-menu-item key="1" @click="showEditModal(record)">
                    编辑
                    </a-menu-item>
                    <a-menu-item key="2" @click="toDel(record)">
                    删除
                    </a-menu-item>
                </a-menu>
                </a-dropdown>
            </template>
        </a-table>
        </div>
        <div class="page">
        <a-pagination
            :pageSizeOptions="pageSizeOptions"
            :total="pageParams.totalCount"
            showSizeChanger
            :pageSize="pageParams.perPage"
            v-model="current"
            @change="changePage"
            @showSizeChange="onShowSizeChange"
            :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
                <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                <span v-if="props.value==='100'">100条/页</span>
            </template>
            </a-pagination>
        </div>
    </div>
    <editModal v-if="isEditModal" :item="modalData" />
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '规则名称', dataIndex: 'rule_name', key: 'rule_name' ,ellipsis:true},
  { title: '客户上限', dataIndex: 'rule_value', key: 'rule_value' },
  { title: '适用范围', dataIndex: 'rule_employee', key: 'rule_employee', scopedSlots: { customRender: 'rule_employee' } },
  { title: '添加人', dataIndex: 'created_by', key: 'created_by' },
  { title: '添加时间', dataIndex: 'created_at', key: 'created_at' },
  { title: '操作', key: 'operation', fixed: 'right', width: 60, scopedSlots: { customRender: 'action' }}
]
    
import editModal from './editModal'
import tableMixins from '@/common/mixins/table'
    export default {
        name:'customerCount',
        data() {
            return {
                columns,
                loading: false,
                isEditModal: false,
                list: [],
                modalData:{}

            }
        },
        mixins: [tableMixins],
        components: {editModal},
        methods: {
            async getList() {
                this.loading = true
                await this.$store.dispatch('settingCrmCustomerConfigAction', this.searchParams)
                .then(res=>{
                    this.list = res.items
                    this.pageParams = res._meta
                    this.loading = false
                })
            },
            showEditModal(item){
                this.modalData = item
                this.isEditModal = true
            },
            hideEditModal(val){
                if(val){
                    this.getList()
                }
                this.isEditModal = false
            },
            toDel(record){
              let that = this
              this.$confirm({
                title: `您确定删除该规则？`,
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                  return new Promise(async (resolve, reject) => {
                    let res = await that.$store.dispatch('settingCrmCustomerConfigDeleteAction', { rule_id: record.rule_id })
                    if (res) {
                      that.getList()
                      that.$message.success('操作成功~')
                      resolve(res)
                    }
                  }).catch(error => console.log(error))
                }
              })
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>