<template>
  <a-modal :title="modalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="500px"
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-form :form="form">
        <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label">
          <a-tree-select
            v-if="item.type === 'studio_ids'"
            v-decorator="[item.name, { rules: item.rules }]"
            tree-checkable
            allowClear
            :tree-data="seleteItems.studios"
            :maxTagCount='3'
            :maxTagTextLength='5'
            :replaceFields="{
              title:'studio_name',
              value: 'studio_id',
            }"
            :dropdownStyle="{maxHeight:'400px'}"
            :show-checked-strategy="SHOW_ALL"
            placeholder="请选择应用校区"
            @change="handleStudio"
          />
          <a-range-picker v-if="item.type === 'time'" v-decorator="[item.name, { rules: item.rules }]" style="width:100%" />
          <a-input v-if="item.type === 'input'" :maxLength="3" v-decorator="[item.name, { rules: item.rules }]"/>
          <a-select v-if="item.type === 'select'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]" showSearch :filterOption="filterOption" mode="multiple" @search="handleSearch">
            <a-select-option v-for="(item, index) of category" :key="index" :value="item.class_id">{{ item.filter_name }}</a-select-option>
          </a-select> 
          <a-date-picker style='width:100%' v-else-if="item.type === 'datetime'" format="YYYY-MM-DD HH:mm" :show-time="{ format: 'HH:mm' }" v-decorator="[item.name, { rules: item.rules }]" />
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>

const formData = [
  {
    name: 'studio_ids',
    label: '应用校区',
    type: 'studio_ids',
    rules: [{ required: true, message: '请选择应用校区!' }],
    items:{
      data:'studios',
      label: 'studio_name',
      value: 'studio_id'
    }
  },
  {
    name: 'timer',
    label: '时间区间',
    type: 'time',
    rules: [{ required: true, message: '请选择时间区间!' }],
  },
  {
    name: 'discount_name',
    label: '折扣名称',
    type: 'input',
    rules: [{ required: false}],
  },
  {
    name: 'class_ids',
    label: '折扣班级',
    type: 'select',
    rules: [{ required: false}],
  },
  {
    name: 'rule_enable_date_time',
    label: '定时开放',
    type: 'datetime',
    rules: [{ required: false}],
  }
]
import moment from "moment"
import { TreeSelect } from 'ant-design-vue'
const SHOW_ALL = TreeSelect.SHOW_ALL
export default {
  name: 'EditModal',
  inject: ['parent'],
  data() {
    return {
      SHOW_ALL,
      loading:false,
      visible:false,
      formData,
      modalTitle:'新增设置',
      confirmLoading:false,
      form: this.$form.createForm(this),
      seleteItems: {
        studios:[],
      },
      category:[],
      studio_ids:[]
    }

  },
  props: {
    item: Object
  },
  async created() {
    this.visible = true
    this.getStudio()
  },
  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getStudio() {
      let res = await this.$store.dispatch('cCategoryStudiosAction', {})
      this.seleteItems.studios = res.data
    },
    async getClass(val) {
      let res = await this.$store.dispatch('searchClassesAction',{params:{q:val},data:{studio_ids:this.studio_ids}})
      this.category = res.data
    },
    async handleOk() {
      const { form } = this
      let params = await form.validateFields()
      this.confirmLoading = true;
      let obj = {
        studio_ids:params.studio_ids,
        open_date:moment(params.timer[0]).format('YYYY-MM-DD'),
        close_date:moment(params.timer[1]).format('YYYY-MM-DD'),
        discount_name:params.discount_name,
        class_ids:params.class_ids,
        rule_enable_date_time:params.rule_enable_date_time?moment(params.rule_enable_date_time).format('YYYY-MM-DD HH:mm:ss'):''
      }
      await this.$store.dispatch('settingSystemEducationAddAppointmentAction',obj)
      .then(res=>{
        this.$message.success('操作成功！！')
        this.parent.hideEditModal(1)
      })
      this.confirmLoading = false;
    },
    handleCancel(){
      this.parent.hideEditModal()
    },
    handleStudio(e){
      if(e.length>0){
        this.studio_ids = e
        this.getClass()
      }else{
        this.category = []
      }
    },
    handleSearch(val){
      this.getClass(val)
    },
  }
}
</script>