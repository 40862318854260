<template>
  <div class="zcxy zl-list">
      <div class="title flts-title">
            <h3>在线协议</h3>
            <a-button type="primary" @click="saveData" :loading="loading">保存</a-button>
        </div>
      <a-form style="height:650px;overflow:auto;">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        
        <a-form-item :label-col="{ span: 3 }" :wrapper-col="{ span: 20 }">
          <l-editor name="agreement" :data="agreement"/>
        </a-form-item>

      </a-form>
  </div>
</template>

<script>

export default {
  name: 'gjjl',
  provide() {
    return {
      parent: this
    }
  },

  data() {
    return {
      loading: false,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],

      editor: {},
      agreement: ''
    }
  },

  created() {
    this.getList()
  },

  methods: {
    closeSearch() {
      this.visible = false
    },
    changeEditor(key, value) {
      this.editor[key] = value
    },
    async getList() {
      let obj = {}
      let { searchData } = this
      this.loading = true;
      let res = await this.$store.dispatch('settingAgreementAction', { })
      this.agreement = res.data.agreement
      this.pageParams = res._meta
      this.loading = false;
    },
    async saveData() {
      this.loading = true;
      let { agreement } = this.editor
      let res = await this.$store.dispatch('settingAgreementAction', { data: { agreement } })
      if (res) {
        this.$message.success('保存成功！')
      }
      this.loading = false;
    }
  }
}
</script>
