<template>
  <a-modal :title="modalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="500px"
    @ok="handleOk" @cancel="handleCancel">
    <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
    <a-form :form="form">
        <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
            <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
            <a-radio-group v-if="item.type === 'radio'" placeholder="请选择" v-decorator="[item.name, { rules: item.rules }]">
                <a-radio :value="0">全部员工</a-radio>
                <a-radio :value="1">部门可用</a-radio>
            </a-radio-group >
            <div v-if="item.type === 'list'">
                <div v-for="(item,index) in list" :key="index" style="display:flex;align-items: center;margin-bottom:5px">
                    <a-input :maxLength="30" @change="e=>handleChange(e,item)" v-model="item.tag_name" placeholder="请输入标签名称" />
                    <a-icon v-if="list.length > 1" @click="delTag(item,index)" style="margin-left:15px" type="minus-circle" />
                </div>
                <div class="customerTag-add">
                    <a-icon type="plus-circle" />
                    <span @click="addTag" style="margin-left:10px">添加标签</span>
                </div>
            </div>
        </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const formData = [
   {
    name: 'group_name',
    label: '标签组名称',
    type: 'input',
    rules: [{ required: true, message: '请输入分组名称!' }],
  },
  {
    name: 'tag_list',
    label: '标签名称',
    type: 'list',
    rules: [{ required: true, message: '请输入分组名称!' }],
  }
]
    export default {
        name:'editModal',
        inject: ['parent'],
        props: {
            item: Object,
        },
        data() {
            return {
                formData,
                visible: false,
                loading: false,
                confirmLoading: false,
                modalTitle:'新增标签组',
                list:[],
                delList:[],
                editList:[],
                form: this.$form.createForm(this),
            }
        },
        async created () {
            this.visible = true
            if(this.item){
                await this.$nextTick()
                this.form.setFieldsValue({group_name:this.item.group_name})
                this.list = this.item.tag_list
            }else{
                this.list = [{id:'',tag_name:'',tag_id:''}]
            }
        },
        methods: {
            async handleOk() {
                let params = await this.form.validateFields()
                let list = this.list
                for(let i=0;i<list.length;i++){
                    if(!list[i].tag_name){
                        this.$message.warning(`请输入第${i+1}个标签名！！`)
                        return false
                    }
                }
                params.tag_list = list
                if(this.item){
                    params.group_id = this.item.group_id
                    let changeList = []
                    this.list.forEach(it=>{
                        if(!it.tag_id){
                            changeList.push(it)
                        }
                    })
                    params.tag_list = [...changeList,...this.editList]
                }
                this.confirmLoading = true
                if(this.delList.length > 0){
                    await this.$store.dispatch('settingWxworkDelTagsAction',{data:{tag_id_list:this.delList}})
                    .then(res=>{})
                }
                await this.$store.dispatch('settingWxworkAddTagsAction',{data:params})
                .then(res=>{
                    this.$message.success('操作成功！！')
                    this.parent.hideEditModal(1)
                    this.confirmLoading = false
                })
                .catch(err=>{
                    console.log(err)
                    this.$message.error('操作失败！！')
                    this.confirmLoading = false
                })

            },
            handleCancel() {
                this.parent.hideEditModal()
            },
            handleChange(e,item){
                let editList = this.editList.map(it=>{return it.tag_id})
                if(item.tag_id && editList.indexOf(item.tag_id) == -1){
                    this.editList.push(item)
                }
            },
            addTag(){
                this.list.push({id:'',tag_name:'',tag_id:''})
            },
            delTag(item,index){
                if(item.tag_id){
                    this.delList.push(item.tag_id)
                    let editList = this.editList.map(it=>{return it.tag_id})
                    let num = editList.indexOf(item.tag_id)
                    if( num != -1){
                        this.editList.splice(num,1)
                    }
                }
                this.list.splice(index,1)
            },
        },
    }
</script>

<style lang="scss" scoped>
    .customerTag-add{
        color: #00cca2;
        cursor: pointer;
    }
</style>