<template>
    <div class="zl-list integralsetting">
        <div class="title flts-title">
            <h3>积分设置</h3>
            <a-button type="primary" @click="submitData" :loading='loading'>保存</a-button>
        </div>
        <div style="height:650px;overflow:auto;">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-form :form="form" layout='vertical'>
                <!-- <a-form-item label="每日签到:" >
                    <a-input style="width:200px" type="number" suffix="积分" v-decorator="rules.signin"/>
                </a-form-item> -->
                <a-form-item label="课程签到:" >
                    <a-input style="width:200px" type="number" suffix="积分" v-decorator="rules.checkin"/>
                </a-form-item>
                <!-- <a-form-item label="动态点赞:" >
                    <a-input style="width:200px" type="number" suffix="积分" v-decorator="rules.praise"/>
                </a-form-item>
                <a-form-item label="动态分享:" >
                    <a-input style="width:200px" type="number" suffix="积分" v-decorator="rules.share"/>
                </a-form-item> -->
                <!-- <a-form-item label="档案点评:" >
                    <a-input style="width:200px" type="number" suffix="积分" v-decorator="rules.comment"/>
                </a-form-item>
                <a-form-item label="作业完成:" >
                    <a-input style="width:200px" type="number" suffix="积分" v-decorator="rules.finish_homework"/>
                </a-form-item>
                <a-form-item label="作业一星:" >
                    <a-input style="width:200px" type="number" suffix="积分" v-decorator="rules.homework_comments_one"/>
                </a-form-item>
                <a-form-item label="作业二星:" >
                    <a-input style="width:200px" type="number" suffix="积分" v-decorator="rules.homework_comments_two"/>
                </a-form-item>
                <a-form-item label="作业三星:" >
                    <a-input style="width:200px" type="number" suffix="积分" v-decorator="rules.homework_comments_three"/>
                </a-form-item>
                <a-form-item label="作业四星:" >
                    <a-input style="width:200px" type="number" suffix="积分" v-decorator="rules.homework_comments_four"/>
                </a-form-item>
                <a-form-item label="作业五星:" >
                    <a-input style="width:200px" type="number" suffix="积分" v-decorator="rules.homework_comments_five"/>
                </a-form-item> -->
            </a-form>
        </div>
    </div>
</template>
<script>
export default {
    name:'IntegralSetting',
    data(){
        return{
            loading:false,
            form: this.$form.createForm(this),
            rules: {
                signin: ['signin', { rules: [{ required: true, message: '请输入积分!' }] }],
                checkin: ['checkin', { rules: [{ required: true, message: '请输入积分!' }] }],
                praise: ['praise', { rules: [{ required: true, message: '请输入积分!' }] }],
                share: ['share', { rules: [{ required: true, message: '请输入积分!' }] }],
                comment: ['comment', { rules: [{ required: true, message: '请输入积分!' }] }],
                finish_homework: ['finish_homework', { rules: [{ required: true, message: '请输入积分!' }] }],
                homework_comments_one: ['homework_comments_one', { rules: [{ required: true, message: '请输入积分!' }] }],
                homework_comments_two: ['homework_comments_two', { rules: [{ required: true, message: '请输入积分!' }] }],
                homework_comments_three: ['homework_comments_three', { rules: [{ required: true, message: '请输入积分!' }] }],
                homework_comments_four: ['homework_comments_four', { rules: [{ required: true, message: '请输入积分!' }] }],
                homework_comments_five: ['homework_comments_five', { rules: [{ required: true, message: '请输入积分!' }] }],
            },
        }
    },
    mounted(){
        this.getSetting();
    },
    methods:{
        async getSetting(){
            this.loading = true
            let res = await this.$store.dispatch('settingCreditIndexAction', {})
            let obj = {};
            res.data.forEach((item,index)=>{
                obj[item.rule_key] = item.points;
            })
            this.form.setFieldsValue(obj);
            this.loading = false
        },
        async submitData(){
            this.loading = true
            let data = await this.form.validateFields();
            let res = await this.$store.dispatch('settingCreditUpdateAction', {credits:data})
            if(res.code == 200){
                this.$message.success('操作成功!')
            }else{
                this.$message.error('操作失败!')
            }
            this.loading=false
        }
    }
}
</script>
<style lang="scss">
    .integralsetting {
        .ant-input-affix-wrapper .ant-input:not(:last-child){
            padding-right: 40px;
        }
    }
</style>