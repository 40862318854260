<template>
  <div class="zl-list">
    <div class="title flts-title">
        <h3>微信公众号授权</h3>
        <a-button type="primary" @click="submitData" :loading='loading' >公众号授权</a-button>
    </div>
    <div>
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <div style="width:100%;height:150px" v-if="list.length == 0">
        <l-Empty />
      </div>
      <div style="text-align:center" v-if="list.length == 0">
        <a-button type="primary" @click="submitData">
          公众号授权
        </a-button>
      </div>
      <div v-if="list.length != 0">
        <a-form>
                <a-form-item :colon="true" label="公众号头像" >
                  <div style="display: inline-block; position: relative;">
                      <div v-if="list.weixin_avatar">
                        <LImg :src="list.weixin_avatar" style="width: 100px; height: 100px;"/>
                      </div>
                  </div>
                </a-form-item>
                <a-form-item label="公众号名称" >
                  <span>{{list.weixin_name}}</span>
                </a-form-item>
                <a-form-item label="appid" >
                  <span>{{list.weixin_key}}</span>
                </a-form-item>
            </a-form>
      </div>
    </div>
  </div>
</template>

<script>
import conf from '@/utils/const'
export default {
  name: 'wexinAuthorization',
  data() {
    return {
      imageUrl: '', 
      loading: false,
      fileList:null,
      fileListTwo:null,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
    }
  },

  created() {
    this.getList()
  },

  methods: {
    async getList() {
      this.loading = true;
      let res = await this.$store.dispatch('weixinSetingAction', { });
      this.list = res.data
      this.loading = false
    },
    async submitData() {
        this.loading = true
        let access_token = this.$ls.get(conf.ACCESS_TOKEN)
        window.open(`/v2b/weixin/auth/index?access_token=${access_token}`)
        // var a = document.createElement("a");
        // a.setAttribute("href", );
        // a.setAttribute("target", "_blank");
        // a.click();
        this.loading = false
    }
  }
}
</script>
