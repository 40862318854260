<template>
    <div>
        <div class="main-box">
            <div class="clearfix table-tools">
                <div class="buttons">
                </div>
                <div class="search">
                    <a-form layout='inline'>
                    <a-form-item>
                        <a-radio-group :value="paramsData.filter_type" @change="handleButChange">
                        <a-radio-button value="day">
                            日
                        </a-radio-button>
                        <a-radio-button value="week">
                            周
                        </a-radio-button>
                        <a-radio-button value="month">
                            月
                        </a-radio-button>
                        <a-radio-button value="year">
                            年
                        </a-radio-button>
                        </a-radio-group>
                    </a-form-item>
                    <a-form-item>
                        <a-tooltip placement="topLeft" >
                        <template slot="title">
                            <span>时间筛选</span>
                        </template>
                        <a-range-picker
                            :ranges="rangesData"
                            :disabled-date="disabledDate" :allowClear="false"
                            :default-value="[moment(paramsData.start_date,'YYYY-MM-DD'),moment(paramsData.end_date,'YYYY-MM-DD')]"  style="width: 240px"
                            @change="(date, dateString)=>onChange(date, dateString,1)" />
                        </a-tooltip>
                    </a-form-item>
                
                    <a-form-item>
                        <a-button @click="toScreen" type="primary">筛选</a-button>
                    </a-form-item>
                    </a-form>
                </div>
            </div>   
            <div class="dashboard bk-group" style="padding-top:15px">
                <a-row class="mb16" :gutter="16">
                    <a-col :lg="24" :xl="24">
                        <VElines :loading='loading' :title="`短信统计（条）`"  :item='leads_data' :start_date='paramsData.start_date' :end_date='paramsData.end_date' />
                    </a-col>
                </a-row>
            </div>
        </div>
    </div>
</template>

<script>
    import ranges from "@/common/mixins/ranges"
    import moment from 'moment'
    export default {
        name:'statistics',
        data() {
            return {
                loading:false,
                leads_data:{},
                paramsData:{
                    start_date:moment().startOf('month').format('YYYY-MM-DD'),
                    end_date:moment().format('YYYY-MM-DD'),
                    filter_type:"day"
                },
            }
        },
        mixins:[ ranges ],
        created () {
            this.toScreen()
        },
        methods: {
            async getList(obj) {
                this.loading = true
                let res = await this.$store.dispatch('settingSystemSmsAnalysisAction', obj)
                this.leads_data = res.data.sms
                this.loading = false
            },
            handleButChange(e){
                this.paramsData.filter_type = e.target.value
                this.toScreen()
            },
            toScreen(){
                let obj = {
                    search:this.paramsData
                }
                this.getList(obj)
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>